import React, { Component } from "react";
import { render } from "react-dom";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  ViewsDirective,
  ViewDirective,
} from "@syncfusion/ej2-react-schedule";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { DataManager, WebApiAdaptor } from "@syncfusion/ej2-data";
import Details_history from "./cards/Details_history.jsx";
import "../calendar/calendar.component.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-calendars/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "@syncfusion/ej2-react-schedule/styles/material.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import { DropDownListComponent } from "@syncfusion/ej2-dropdowns";
import { DateTimePickerComponent } from "@syncfusion/ej2-calendars";
import { L10n } from "@syncfusion/ej2-base";
import axios from "axios";
// import VaccinationUpdate from '../medicaldashboard/update-record/vaccinationupdate.component.jsx';
// import AllergyUpdate from '../medicaldashboard/update-record/allergyupdate.component.jsx';
// import MedicalconditionUpdate from '../medicaldashboard/update-record/medicalconditionupdate.component.jsx';
// import SurgeryUpdate from '../medicaldashboard/update-record/surgeryupdate.component.jsx';
// import MedicationUpdate from '../medicaldashboard/update-record/medicationupdate.component.jsx';
// import MeasurementUpdate from '../medicaldashboard/update-record/measurementupdate.component.jsx';

L10n.load({
  "en-US": {
    schedule: {
      saveButton: "",
      deleteButton: "",
    },
  },
});

export default class Calendar extends Component {
  // constructor(props) {
  //   // medical dashboard edits
  //   super(...arguments, props);
  //   this.dataManger = new DataManager({
  //     url: "/api/pet/getMedicalNotification/" + arguments[0].match.params.uid,
  //     publicKey: "AIzaSyD76zjMDsL_jkenM5AAnNsORypS1Icuqxg",
  //     adaptor: new WebApiAdaptor(),
  //     crossDomain: true,
  //   });
  // }
  // editorWindowTemplate(props) {
  //   console.log(props);
  //   window.localStorage.setItem("tag_number", props.tag_number);
  //   if (props.vaccination_id) {
  //     return <VaccinationUpdate vaccination_id={props.vaccination_id} />;
  //   } else if (props.allergy_id) {
  //     return <AllergyUpdate allergy_id={props.allergy_id} />;
  //   } else if (props.medicalcondition_id) {
  //     return (
  //       <MedicalconditionUpdate
  //         medicalcondition_id={props.medicalcondition_id}
  //       />
  //     );
  //   } else if (props.surgery_id) {
  //     return <SurgeryUpdate surgery_id={props.surgery_id} />;
  //   } else if (props.medication_id) {
  //     return <MedicationUpdate medication_id={props.medication_id} />;
  //   } else if (props.measurement_id) {
  //     return <MeasurementUpdate measurement_id={props.measurement_id} />;
  //   } else {
  //     return <div></div>;
  //   }
  // }
  // OnEventRendered(args) {
  //   args.element.style.marginLeft = "-15px";
  //   args.element.style.marginTop = "15px";
  //   var date1 = new Date(args.data.StartTime);
  //   var date2 = new Date(Date.now());
  //   var description = args.data.description;
  //   //   if((date1 - date2) >= 0){
  //   //     args.element.style.backgroundColor = 'white';
  //   //     args.element.style.color = 'blue';
  //   // }
  //   // else{
  //   //     args.element.style.backgroundColor = 'lightgrey';
  //   //     args.element.style.color = 'black';
  //   // }
  // }
  // onActionBegin(args) {
  //   console.log(args);
  //   if (args.requestType === "eventRemove") {
  //     axios.post("/api/pet/deleteMedicalDetails", args.deletedRecords[0]);
  //     window.location.reload();
  //   }
  // }
  // render() {
  //   return (
  //     <div className="calendar_container">
  //       <div
  //         className="schedule-control-section"
  //         style={{ backgroundColor: "chocolate" }}
  //       >
  //         <div className="control-section">
  //           <div className="control-wrapper">
  //             {/* <ButtonComponent id='edit' title='Edit' ref={t => this.buttonObj = t} onClick={this.onEditClick.bind(this)}>Edit</ButtonComponent> */}
  //             <ScheduleComponent
  //               className="myschedule"
  //               currentView="Month"
  //               height="750px"
  //               ref={(t) => (this.scheduleObj = t)}
  //               eventRendered={this.OnEventRendered}
  //               style={{ paddingLeft: "0px" }}
  //               col
  //               eventSettings={{ dataSource: this.dataManger }}
  //               readonly={false}
  //               actionBegin={this.onActionBegin.bind(this)}
  //               editorTemplate={this.editorWindowTemplate.bind(this)}
  //             >
  //               {/* <ViewsDirective style={{ marginLeft: '0px', backgroundColor: 'blue' }} className="myviewdiective">
  //                 <ViewDirective className="innerdirective" option='Month' />
  //               </ViewsDirective> */}
  //               <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
  //             </ScheduleComponent>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="scheduler_history">
  //         <Details_history uid={this.props.match.params.uid} />
  //       </div>
  //     </div>
  //   );
  // }
}
