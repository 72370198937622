import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import axios from "axios";
import Button from "@material-ui/core/Button";
import KeyReportFound from "./key/reportfound.component.jsx";
import PetReportFound from "./pet/reportfound.component.jsx";
import GenericReportFound from "./generic/scanpage.component.jsx";
import "./scanrouting.component.css";

function ScanRouting(props) {
  // const [notfound, shownotfound] = useState(false)
  // const [notactivated, shownotactivated] = useState(false)
  // const [initialletter, showinitialletter] = useState('')
  useEffect(() => {
    console.log(props.match.params.tag_number)
    const tagnumber = props.match.params.tag_number
    window.location.href = "https://dashboard.tag8.in/#/scan/" + tagnumber
    // const initial = tagnumber.substring(0, 3)
    // console.log(initial)
    // showinitialletter(initial)
    // axios.post('https://dashboard.tag8.in/api/checkTagNumberWithoutHeader', { tag_number: tagnumber }).then((res) => {
    //   if (res.data === 'Tag not Found by the specified Number') {
    //     shownotfound(true)
    //   }
    //   else if (res.data === 'Tag Activated Successfully') {
    //     shownotactivated(true)
    //   }
    // }).catch((error) => {
    //   console.log(error)
    // })
  })
  // const handleClose = () => {
  //   shownotfound(false);
  // }
  // const handleClose1 = () => {
  //   shownotactivated(false);
  // }
  // const onNotFound = () => {
  //   shownotfound(false);
  //   const tagnumber = props.match.params.tag_number
  //   const initial = tagnumber.substring(0, 3)
  //   if (initial === 'PET') {
  //     window.location.href = "/pet"
  //   }
  //   else if (initial === 'KEY') {
  //     window.location.href = "/key"
  //   }
  //   else if (initial === 'TGZ') {
  //     window.location.href = "/pet"
  //   }
  //   else if (initial === 'TGY') {
  //     window.location.href = "/key"
  //   }
  //   else {
  //     window.location.href = "/login"
  //   }
  // }
  // const onActivated = () => {
  //   window.location.href = "/scan"
  // }
  return (
    <div>
      {/* {initialletter ?
        initialletter === 'PET' ? < PetReportFound tag_number={props.match.params.tag_number} /> : "" : ""
      }
      {initialletter ?
        initialletter === 'KEY' ? < KeyReportFound tag_number={props.match.params.tag_number} /> : "" : ""
      }
      {initialletter ?
        initialletter === 'TGZ' ? < PetReportFound tag_number={props.match.params.tag_number} /> : "" : ""
      }
      {initialletter ?
        initialletter === 'TGY' ? < KeyReportFound tag_number={props.match.params.tag_number} /> : "" : ""
      }
      {initialletter ?
        initialletter === 'BDT' ? < GenericReportFound tag_number={props.match.params.tag_number} /> : "" : ""
      }
      {initialletter ?
        initialletter === 'DOL' ? < GenericReportFound tag_number={props.match.params.tag_number} /> : "" : ""
      }
      <Dialog disableBackdropClick open={notfound} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent className="small__popup">
          <h1
            style={{
              margin: "20px",
              marginTop: "10px",
              font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
              letterSpacing: "normal",
            }}
          >
            Tag number not found
          </h1>
          <div className="text-center mb-4">
            <Button
              variant="outlined"
              className="small_pop_btn"
              onClick={onNotFound}
            >
              Ok
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        disableBackdropClick
        open={notactivated}
        onClose={handleClose1}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="small__popup">
          <h1
            style={{
              margin: "20px",
              marginTop: "10px",
              font: "500 20px/32px Roboto,Helvetica Neue,sans-serif",
              letterSpacing: "normal",
            }}
          >
            Tag is not activated
          </h1>
          <div className="text-center mb-4">
            <Button
              variant="outlined"
              className="small_pop_btn"
              onClick={onActivated}
            >
              Ok
            </Button>
          </div>
        </DialogContent>
      </Dialog> */}
    </div>
  );
}

export default ScanRouting;
