import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../petposter/petposter.component.css";
// import Logo from "../../assets/genielogofinal.png";
// import CatDog from "../../assets/catdog1.jpg";
import "font-awesome/css/font-awesome.min.css";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import "../petposter/petposter.component.css";
import axios from "axios";
import html2canvas from "html2canvas";
// import blood from "../../assets/genievectors/blood.svg";
// import color from "../../assets/genievectors/color.svg";
// import email from "../../assets/genievectors/email.png";
// import call from "../../assets/genievectors/call.png";
// import phone from "../../assets/genievectors/phone-call.png";
// import paw from "../../assets/genievectors/animal.svg";
// import gender from "../../assets/genievectors/gender.svg";
// import breed from "../../assets/genievectors/breed.svg";
// import money from "../../assets/genievectors/money.jpeg";
import * as FaIcons from "react-icons/fa";

import { switchUrls } from "../../api/index.js";
// import { genieurl } from "../../api/route";
const baseURL = switchUrls("genie");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

function toTitleCase(str) {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0).toUpperCase() + txt?.substr(1).toLowerCase();
  });
}

export default class PetPoster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tag_number: "",
      tag_status: "",
      uid: "",
      pet_breed: "",
      pet_gender: "",
      pet_age: "",
      pet_color: "",
      address: "",
      valuable_type: "",
      showAge: "",
      pet_ident: "",
      lost_date: "",
      addressloc: "",
      pet_name: "",
      rewardcurrency: "",
      phone_one: "",
      reward: "",
      latitude: "",
      longitude: "",
      pet_imagelocation: null,
      pet_imagename: null,
      parent_firstname: "",
      parent_lastname: "",
      active: false,
      showdiv: false,
      imageUrlvector: null,
      showLoader: true,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  showOptions() {
    if (this.state.showdiv) {
      document.getElementById("bdiv").style.bottom = "-4rem";
      document.getElementById("aup").style.display = "block";
      document.getElementById("adown").style.display = "none";

      this.setState({ showdiv: false });
    } else {
      document.getElementById("bdiv").style.bottom = "0";
      document.getElementById("aup").style.display = "none";
      document.getElementById("adown").style.display = "block";

      this.setState({ showdiv: true });
    }
  }
  // downloadposter() {
  //   window.scrollTo(0, 0);
  //   // let elem = document.getElementById("canvasWrapper");
  //   setTimeout(() => {
  //     const elem = document.querySelector(".poster-download");

  //     html2canvas(elem).then(function (canvas) {
  //       let generatedImage = canvas
  //         .toDataURL("image/png")
  //         .replace("image/png", "image/octet-stream");

  //       let a = document.createElement("a");
  //       a.href = generatedImage;
  //       a.download = `pet-poster.png`;
  //       a.click();
  //     });
  //   }, 1000);
  // }

  downloadposter() {
    const contentToConvert = document.getElementById("contentToConvert");

    const clone = contentToConvert.cloneNode(true);
    document.body.appendChild(clone);

    const helpMeReturn = clone.querySelector("#bdiv");
    helpMeReturn.style.position = "absolute";
    helpMeReturn.style.bottom = "0";

    html2canvas(clone).then(function (canvas) {
      let generatedImage = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      let a = document.createElement("a");
      a.href = generatedImage;
      a.download = `pet-poster.png`;
      a.click();

      document.body.removeChild(clone);
    });
  }

  toggleMenu() {
    this.setState({
      active: !this.state.active,
    });
  }

  componentDidMount() {
    // this.listener = document.addEventListener("scroll", (e) => {
    //   var scrolled = document.scrollingElement.scrollTop;

    //   if (scrolled >= 20) {
    //     document.getElementById("genie-nav").style.backgroundColor = "white";
    //   } else {
    //     document.getElementById("genie-nav").style.backgroundColor =
    //       "transparent";
    //   }
    // });

    const access_token = usertoken;

    const getURL =
      `${baseURL}/api/pet/getreportlocation/` +
      this.props.match.params.tag_number;
    axios
      .get(getURL, {
        headers: {
          Authorization: access_token,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("getreportlocation resp", response);
        const lastIndexData =
          response.data.length > 0 ? response.data.length - 1 : 0;
        this.setState({
          address: response.data.data[lastIndexData].address,
          latitude: response.data.data[lastIndexData].latitude,
          longitude: response.data.data[lastIndexData].longitude,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        `${baseURL}/api/pet/getPetImagePoster/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          pet_imagelocation: response.data.pet_imagelocation,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    const getURL1 =
      `${baseURL}/api/pet/getLostDetails/` + this.props.match.params.tag_number;
    axios
      .get(getURL1, {
        headers: {
          Authorization: usertoken,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const lastIndexData =
          response.data.length > 0 ? response.data.length - 1 : 0;
        console.log("getLostDetails resp: ", response);
        // console.log("rc",response.data[0].rewardcurrency,);
        this.setState({
          valuable_type: response.data[lastIndexData].valuable_type,
          pet_breed: response.data[lastIndexData].pet_breed,
          pet_gender: response.data[lastIndexData].pet_gender,
          pet_age: response.data[lastIndexData].pet_age,
          pet_color: response.data[lastIndexData].pet_color,
          pet_ident: response.data[lastIndexData].pet_ident,
          pet_name: response.data[lastIndexData].pet_name,
          pet_bloodgrp: response.data[lastIndexData].pet_bloodgrp,
          lost_date: response.data[lastIndexData].lost_date,
          phone_one: response.data[lastIndexData].phone_one,
          reward: response.data[lastIndexData].reward,
          rewardcurrency: response.data[lastIndexData].reward_currency,
          parent_firstname: response.data[lastIndexData].parent_firstname,
          parent_lastname: response.data[lastIndexData].parent_lastname,
          parent_email: response.data[lastIndexData].parent_email,
          setScrollNav: false,
        });

        if (response.data[0].valuable_type === "Dog") {
          this.setState({
            imageUrlvector:
              "https://storage.googleapis.com/pettag/qr/assets/dog.png",
          });
        } else if (response.data[0].valuable_type === "Cat") {
          this.setState({
            imageUrlvector:
              "https://storage.googleapis.com/pettag/qr/assets/cat.png",
          });
        }

        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const {
      parent_firstname,
      parent_lastname,
      parent_email,
      pet_bloodgrp,
      pet_imagelocation,
      pet_breed,
      pet_gender,
      pet_age,
      pet_color,
      pet_ident,
      pet_name,
      lost_date,
      phone_one,
      reward,
      rewardcurrency,
      address,
      valuable_type,
      latitude,
      longitude,
      imageUrlvector,
    } = this.state;

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <div className="genieposter-nav" id="genie-nav">
          <img
            src={"https://storage.googleapis.com/pettag/qr/genielogofinal.png"}
            alt="logo"
            style={{ width: "6rem" }}
          />

          <div className="menu-bars" onClick={this.toggleMenu}>
            <FaIcons.FaBars />
          </div>
        </div>

        <div className="row text-center pt-4 extra-btns">
          <div className="col">
            <Button
              variant="contained"
              className="bg-warning postar-res button-styling mt-3"
              onClick={this.downloadposter}
              style={{ width: "80%", padding: "1rem" }}
            >
              Download
            </Button>
          </div>

          <div className="col">
            <a
              href={"/#/edit-report-lost/" + this.props.match.params.tag_number}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                className="bg-warning postar-res button-styling mt-3"
                style={{ width: "80%", padding: "1rem" }}
              >
                Edit
              </Button>
            </a>
          </div>

          <div className="col">
            <Button
              variant="contained"
              onClick={() => {
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              }}
              className="bg-warning postar-res button-styling mt-3"
              style={{ width: "80%", padding: "1rem" }}
            >
              Go To My Tags
            </Button>
          </div>
        </div>

        {/* <div className="menu-overlay-div"> */}
        {/* <ul
          className={active ? "menu-overlay menu-overlay-show" : "menu-overlay"}
        > */}
        <ul
          className="menu-overlay-show"
          style={{ display: this.state.active ? "flex" : "none" }}
        >
          <li className="menu-overlay-links">
            <div
              className="emergency-btn"
              style={{
                display: "flex",
                textAlign: "center",
                fontSize: "1.1rem",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "500",
              }}
            >
              Medical Emergency ?
            </div>
          </li>

          <li className="menu-overlay-links-down">
            <Button onClick={this.downloadposter}>Download</Button>
          </li>

          <li className="menu-overlay-links-edit">
            <a
              href={"/#/edit-report-lost/" + this.props.match.params.tag_number}
            >
              <Button>Edit</Button>
            </a>
          </li>

          <li className="menu-overlay-links-go">
            <Button
              onClick={() => {
                window.location.href =
                  "/dashboard/" + localStorage.getItem("uid");
              }}
            >
              Go To My Tags
            </Button>
          </li>
        </ul>
        {/* </div> */}

        <div id="contentToConvert" className="pb-3 poster-download">
          <div className="canvasWrapper" id="canvasWrapper">
            <Card className="mb-5 petPoster-mat-card">
              <div className="row text-center p5">
                {valuable_type ? (
                  <p className="header-font lost-valuable header-text mt-5 pb-4">
                    LOST {valuable_type.toUpperCase()} !
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="row d-flex align-items-center">
                <div className="col-md-5 img-container colorw d-flex flex-column align-items-center justify-content-center">
                  <div className="main-img">
                    {pet_imagelocation ? (
                      <img
                        alt=""
                        src={`data:image/png;base64,${pet_imagelocation}`}
                        className="mat-card-image"
                        width="auto"
                        height="340px"
                      />
                    ) : (
                      <img
                        alt="Pet-Default-Vector-Image"
                        className="petposter-card-image-default"
                        src={imageUrlvector}
                      />
                    )}
                  </div>

                  <div className="card-bg">
                    <div className="pet-name">{toTitleCase(pet_name)}</div>

                    <div className="pet-age">
                      <img
                        src={
                          "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/animal.svg"
                        }
                        alt=""
                        className="vector"
                      />
                      {pet_age} year old {toTitleCase(valuable_type)}
                    </div>
                  </div>
                </div>

                <div className="col-md-7 colorw 1 details-right-side">
                  <div className="flex">
                    {pet_breed ? (
                      <div className="text-center">
                        <p className="text-center lost-info-text ">
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/color.svg"
                            }
                            alt="color"
                            className="vector"
                          />
                          {toTitleCase(pet_color)}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    {pet_gender ? (
                      <div className="text-center">
                        <p className="text-center lost-info-text">
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/gender.svg"
                            }
                            alt="gender"
                            className="vector"
                          />
                          {toTitleCase(pet_gender)}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="flex">
                    {pet_color ? (
                      <div className="text-center">
                        <p className="text-center lost-info-text">
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/breed.svg"
                            }
                            alt="breed"
                            className="vector"
                          />
                          {toTitleCase(pet_breed)}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    {pet_ident ? (
                      <div className="text-center">
                        <p className="text-center lost-info-text">
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qrtagdev/assets/genievectors/blood.svg"
                            }
                            alt="blood"
                            className="vector"
                          />
                          {toTitleCase(pet_bloodgrp)}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row colorw"></div>
              </div>

              {lost_date || address ? (
                <div className="reported-lost-data">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <div className="card bg-light mb-3" style="max-width: 18rem;">
                    <div className="card-header">Header</div>
                    <div className="card-body">
                      <h5 className="card-title">Light card title</h5>
                      <p className="card-text">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                    </div>
                  </div> */}

                    <p className="header-font lost-info-header">
                      Lost Reported Data
                    </p>
                    <span className="lost-info-span">
                      <p
                        className="text-center for-margin lost-info-text"
                        style={{ fontSize: "1em" }}
                      >
                        Lost Date:{lost_date}
                      </p>

                      <p
                        className="text-center for-margin  lost-info-text"
                        style={{ fontSize: "1em" }}
                      >
                        Lost Reward
                        <img
                          src={
                            "https://storage.googleapis.com/pettag/qr/money.png"
                          }
                          alt="money"
                          className="vector"
                        />
                        {toTitleCase(rewardcurrency)} {toTitleCase(reward)}
                      </p>
                    </span>
                    <p className="text-center lost-info-text-address">
                      {latitude !== "" && longitude !== "" ? (
                        <a
                          href={`https://maps.google.com/maps?q=${latitude},${longitude}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {address}
                        </a>
                      ) : null}
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Card>

            <div className="text-center pt-3 bottom-div" id="bdiv">
              <i
                className="fa fa-angle-up"
                id="aup"
                aria-hidden="true"
                onClick={this.showOptions.bind(this)}
              ></i>

              <i
                className="fa fa-angle-down"
                id="adown"
                style={{ display: "none" }}
                aria-hidden="true"
                onClick={this.showOptions.bind(this)}
              ></i>
              <p style={{ fontSize: "26px", fontFamily: "ABeeZee" }}>
                Help me return home
              </p>

              <div className="bottom-inner-div">
                {phone_one ? (
                  <>
                    <a href={`tel:+${phone_one}`}>
                      <p className="pabr footer-font flex-between">
                        {" "}
                        <div style={{ display: "inline-block" }}>
                          <img
                            classname="vector"
                            src={
                              "https://storage.googleapis.com/pettag/qr/call.png"
                            }
                          />
                          {/* Call Owner */}
                          {phone_one}
                        </div>
                        {/* <div style={{ display: "inline-block" }}>&gt;</div> */}
                      </p>
                    </a>
                  </>
                ) : (
                  ""
                )}

                {phone_one ? (
                  <>
                    <a
                      href={`https://wa.me/${phone_one}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p className="pabr flex-between footer-font text-center">
                        <div style={{ display: "inline-block" }}>
                          <img
                            classname="vector"
                            src={
                              "https://storage.googleapis.com/pettag/qr/phone-call.png"
                            }
                          />
                          {/* Whatsapp Owner */}
                          {toTitleCase(parent_firstname)}{" "}
                          {toTitleCase(parent_lastname)}
                        </div>
                        {/* <div style={{ display: "inline-block" }}>&gt;</div> */}
                      </p>
                    </a>
                  </>
                ) : (
                  ""
                )}

                {parent_email ? (
                  <>
                    <a href={`mailto:${parent_email}`}>
                      <p className="flex-between footer-font text-center pabr">
                        <div style={{ display: "inline-block" }}>
                          <img
                            classname="vector"
                            src={
                              "https://storage.googleapis.com/pettag/qr/email.png"
                            }
                          />
                          {/* E-Mail Owner */}
                          {parent_email}
                        </div>
                        {/* <div style={{ display: "inline-block" }}>&gt;</div> */}
                      </p>
                    </a>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.showLoader && (
          <div className="loader-container">
            <div className="loader">
              <img
                src={
                  "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                }
                alt="Loading..."
                loading="lazy"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}
