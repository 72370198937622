import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../editpetdetails/editpetdetails.component.css";
// import CatDog from "../../assets/catdog1.jpg";
// import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import imageCompression from "browser-image-compression";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../api/index.js";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  FormLabel,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import PetImage from "../../assets/pet.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "../../setcanvasPreview.js";

const baseURL = switchUrls("genie");

const RadioButton = withStyles({
  //nishant edits
  root: {
    color: purple[900],
    "&$checked": {
      color: purple[600],
    },
  },

  checked: {},
})((props) => <Radio color="default" {...props} />);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) == " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");
var today = new Date();

const dd = String(today.getDate()).padStart(2, "0");
const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
const yyyy = today.getFullYear();
const dtoday = yyyy + "-" + mm + "-" + dd;

export default class EditPetDetails extends React.Component {
  fileObj = [];
  fileArray = [];

  constructor(props) {
    super(props);
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.fileInputRef = React.createRef();

    this.state = {
      imagesArray: "",
      imageUrl: "",
      pet_imagelocation: null,
      pet_imagename: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "",
      pet_name: "",
      pet_breed: "",
      pet_color: "",
      pet_gender: "",
      pet_dob: "",
      pet_age: "",
      pet_ident: "",
      pet_microchip: "",
      pet_bloodgrp: "",
      pet_colorother: "",
      pet_breedother: "",
      pet_bloodgrpother: "",
      snackbaropen: false,
      snackbarmsg: "",
      message: "",
      redirecttopetparent: true,
      file: [null],
      sidebar: true,
      subMenu: true,
      showPetDetails: true,
      showPet_ParentDetails: false,
      isUploadingImg: false,
      imageUrlvector: "https://storage.googleapis.com/pettag/qr/assets/cat.png",
      valuableImages: {
        Dog: "https://storage.googleapis.com/pettag/qr/assets/dog.png",
        Cat: "https://storage.googleapis.com/pettag/qr/assets/cat.png",
      },
      showLoader: true,
      imagemodal: false,
      crop: "",
      uploaderror: false,
      imagecroploading: false,
      errorstate: null,
      croperror: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.onImgChange = this.onImgChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }
  async handleChange(event) {
    this.setState({ imagecroploading: true });
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);

      if (sizeinmb > 5) {
        this.setState({
          message: "File size should be less than 5 MB",
          uploaderror: true,
          imagemodal: true,
        });
      } else {
        this.setState({ showLoader: true });
        const imageDimension = await this.imageSize(event.target.files[0]);
        //console.log(imageDimension);
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight:
            imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(
          event.target.files[0],
          options
        );
        //console.log(compressedFile);

        const selectedImage = compressedFile;
        //console.log(selectedImage);
        this.setState({ showLoader: false });
        if (selectedImage?.type) {
          if (
            selectedImage?.type === "image/jpeg" ||
            selectedImage?.type === "image/png" ||
            selectedImage?.type === "image/gif"
          ) {
            this.setState({
              imagemodal: true,
              selectedImage: compressedFile,
              imageUrl: URL.createObjectURL(compressedFile),
              isuploading: true,
              imagecroploading: false,
            });
          } else {
            this.setState({
              message: "Only Png and Jpeg Images Are Acceptable",
              uploaderror: true,
              imagemodal: true,
              imagecroploading: false,
            });
          }
        } else {
          this.setState({
            message: "Only Png and Jpeg Images Are Acceptable",
            uploaderror: true,
            imagemodal: true,
            imagecroploading: false,
          });
        }
        if (this.fileInputRef.current) {
          this.fileInputRef.current.value = "";
        }
      }
    } else {
      console.log("failed");
    }
  }
  cropsetup(e) {
    console.log("dddddddddddd");
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  handelimagecrop() {
    setCanvasPreview(
      this.imageref?.current,
      this.canvasref?.current,
      convertToPixelCrop(
        this.state.crop,
        this.imageref?.current?.width,
        this.imageref?.current?.height
      )
    );
    console.log(this.state?.selectedImage);
    this.canvasref?.current?.toBlob((blob) => {
      let file = new File([blob], this.state?.selectedImage?.name, {
        type: "image/jpeg",
      });
      this.setState({
        selectedImage: file,
        imageUrl: URL.createObjectURL(blob),
        key_imagelocation: null,
        isuploading: true,
        imagemodal: false,
      });
    });
  }

  componentDidMount() {
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-2").on("click", function () {
      $("#choose-profile-imageActi-2").trigger("click");
    });

    // console.log(this.props.match.params.tag_number, "tagunenen");
    axios
      .get(
        `${baseURL}/api/pet/getPetParentDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log("res: ", response);
        this.setState({
          pet_imagelocation: response.data.pet_imagelocation,
          pet_imagename: response.data.pet_imagename,
          uid: response.data.uid,
          // tag_number: response.data.tag_number,
          tag_status: response.data.tag_status,
          valuable_type: response.data.valuable_type,
          pet_name: response.data.pet_name,
          pet_breed: response.data.pet_breed,
          pet_color: response.data.pet_color,
          pet_gender: response.data.pet_gender,
          pet_dob: response.data.pet_dob,
          pet_age: response.data.pet_age,
          pet_ident: response.data.pet_ident,
          pet_microchip: response.data.pet_microchip,
          pet_bloodgrp: response.data.pet_bloodgrp,
          pet_colorother: response.data.pet_colorother,
          pet_breedother: response.data.pet_breedother,
          pet_bloodgrpother: response.data.pet_bloodgrpother,
        });

        // if (response.data.parent_firstname === undefined || "") {
        //   this.setState({ redirecttopetparent: true });
        // } else {
        //   this.setState({ redirecttopetparent: false });
        // }
        if (response.data.valuable_type === "Dog") {
          this.setState({
            imageUrlvector:
              "https://storage.googleapis.com/pettag/qr/assets/dog.png",
          });
        } else if (response.data.valuable_type === "Cat") {
          this.setState({
            imageUrlvector:
              "https://storage.googleapis.com/pettag/qr/assets/cat.png",
          });
        }
        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 1000);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  onImgChange(event) {
    this.setState({ imagesArray: "", file: [null] });
    this.fileObj = [];
    this.fileArray = [];
    this.setState({
      imagesArray: [...this.state.imagesArray, ...event.target.files],
    });
    this.fileObj.push(event.target.files);

    for (let i = 0; i < this.fileObj[0].length; i++) {
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }

    this.setState({ file: this.fileArray });
  }

  handleDropdownChange(event) {
    // console.log("valueType: ", event);
    this.setState({
      valuable_type: event.target.value,
      imageUrlvector: this.state.valuableImages[event.target.value],
    });
  }

  changeHandlerdate = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    const birthDate = new Date(e.target.value);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);
    const ageyear = Math.abs(age.getUTCFullYear() - 1970);

    this.setState({ pet_age: ageyear });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  deleteimage = (e) => {
    axios
      .post(`${baseURL}/api/pet/deleteimage`, this.state)
      .then((response) => {
        if (response.data === "Pet Image removed") {
          this.setState({
            imageUrl: "",
            pet_imagelocation: null,
            pet_imagename: null,
          });
        } else if (response.data === "wrong data") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Image could not be deleted",
          });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
      });

    this.setState({
      imageUrl: null,
      pet_imagelocation: null,
      pet_imagename: null,
      imageUrlvector: this.state.imageUrlvector,
      isUploadingImg: false,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();

    axios
      .post(
        `${baseURL}/api/pet/editpetdetails/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        if (response.data === "Pet Details Updated") {
          if (this?.state?.selectedImage) {
            const fd = new FormData();

            fd.append("image", this.state.selectedImage);
            fd.append("tag_number", this.props.match.params.tag_number);

            axios
              .post(`${baseURL}/api/pet/petimage-upload`, fd)
              .then((res) => {
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Pet Details Updated",
                });

                this.state.redirecttopetparent
                  ? (window.location.href =
                      "/#/pet-parent-details/" +
                      this.props.match.params.tag_number)
                  : (window.location.href =
                      "/#/dashboard/" + localStorage.getItem("uid"));
              })
              .catch((error) => {
                console.error(error.message);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Unsuccessful",
                });
              });
          } else {
            this.state.redirecttopetparent
              ? (window.location.href =
                  "/#/pet-parent-details/" + this.props.match.params.tag_number)
              : (window.location.href =
                  "/#/dashboard/" + localStorage.getItem("uid"));
          }
        } else if (response.data === "wrong data") {
          this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
        }
      })
      .catch((error) => {
        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });

        console.error(error.message);
      });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  // Breadcrumb handle click
  swithBreadcrumbs = (e, tab) => {
    // if (tab === "PET_DETAILS") {
    //   this.setState({ showPetDetails: true });
    //   this.setState({ showPet_ParentDetails: false });
    // }
    if (tab === "PET_PARENT_DETAILS") {
      window.location.href =
        "/#/pet-parent-details/" + this.props.match.params.tag_number;
    }
  };

  render() {
    const {
      sidebar,
      subMenu,
      pet_imagelocation,
      imageUrl,
      tag_number,
      valuable_type,
      pet_name,
      pet_breed,
      pet_color,
      pet_gender,
      pet_dob,
      pet_age,
      pet_ident,
      pet_microchip,
      pet_bloodgrp,
      pet_colorother,
      pet_breedother,
      pet_bloodgrpother,
      isUploadingImg,
      imageUrlvector,
      imagemodal,
      uploaderror,
      crop,
      message,
    } = this.state;
    // console.log("state: ", this.state);
    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };
    return (
      <>
        <Navbar />

        <div className="editPetDetails-main-wrapper">
          <div className="editPetDetails-left-wrapper">
            <div className="editPetDetails-sidebar-toggle-btn-wrapper">
              <button
                className="editPetDetails-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "editPetDetails-sidebar-container-hide"
                  : "editPetDetails-sidebar-container"
              }
            >
              <button
                className="editPetDetails-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS{" "}
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "editPetDetails-sidebar-menu-ul-hide"
                    : "editPetDetails-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="editPetDetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    All Tags
                  </button>
                </li>

                <li>
                  <button
                    className="editPetDetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    Active | Inactive Tags
                  </button>
                </li>

                <li>
                  <button
                    className="editPetDetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    Lost | Found Tags
                  </button>
                </li>
              </ul>

              <button
                className="editPetDetails-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + this.props.match.params.tag_number;
                }}
              >
                ACTIVATE TAG
              </button>

              <button
                className="editPetDetails-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/medical-dashboard/" +
                    this.props.match.params.tag_number;
                }}
              >
                PET MEDICAL DETAILS
              </button>

              <button
                className="editPetDetails-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>
            </div>
          </div>

          <div className="form-group multi-preview">
            {(this.fileArray || []).map((url) => (
              <img
                src={url}
                className="ml-4 mr-2"
                alt="..."
                height="70px"
                width="70px"
              />
            ))}
          </div>

          <div className="editPetDetails-right-wrapper">
            <div id="breadcrumbs" className="mb-3">
              <Breadcrumbs
                className="mui-breadcrumb"
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
              >
                <Link
                  className="breadcrumb-link"
                  color="textPrimary"
                  onClick={(e) => this.swithBreadcrumbs(e, "PET_DETAILS")}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "12px",
                    color: "#fcbc1b",
                  }}
                >
                  Pet Details
                </Link>
                <Link
                  className="breadcrumb-link"
                  color="inherit"
                  onClick={(e) =>
                    this.swithBreadcrumbs(e, "PET_PARENT_DETAILS")
                  }
                  style={{
                    cursor: "pointer",
                    fontSize: "12px",
                    color: "#fcbc1b",
                  }}
                >
                  Pet Parent Details
                </Link>
              </Breadcrumbs>
            </div>
            <div className="petDetails ">
              <div className="editPetDetails-form-container ">
                <div className="editPetDetails-form-container-top">
                  <div className="editPetDetails-user-img">
                    {imageUrl ? (
                      <img
                        alt="pet-img-uploaded"
                        src={imageUrl}
                        className="editPetDetails-image rounded-circle"
                      />
                    ) : pet_imagelocation ? (
                      <img
                        alt="pet-img-uploadeding"
                        src={pet_imagelocation}
                        className="editPetDetails-image rounded-circle"
                      />
                    ) : (
                      <img
                        alt="pet-img-default"
                        src={imageUrlvector}
                        className="editPetDetails-image"
                        style={{ padding: "1.5rem" }}
                      />
                    )}
                  </div>
                  <div style={{display:"flex"}} className="editPetDetails-edit-btn-wrap">
                   <div style={{marginRight:"1rem"}}>
                   <label
                      htmlFor="upload"
                      className="editPetDetails-edit-button-image"
                      style={{ cursor: "pointer" }}
                    >
                      <MdIcons.MdEdit /> Change
                    </label>

                    <input
                      ref={this.fileInputRef}
                      id="upload"
                      className="editPetDetails-input-user-img"
                      type="file"
                      onChange={this.handleChange}
                    />

                   </div>
                   <div>
                   {pet_imagelocation || imageUrl ? (
                      <label
                        className="editPetDetails-delete-button-image "
                        onClick={this.deleteimage}
                      >
                        <AiIcons.AiFillDelete /> Remove
                      </label>
                    ) : null}
                   </div>
                  </div>
                  <div
                    className=" editPetDetails-user-qr mt-3 d-flex"
                    style={{ gap: "6px" }}
                  >
                    <img
                      alt="qr"
                      src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                      style={{ width: "30px", height: "30px" }}
                    />
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "1.2rem",
                        margin: "auto",
                      }}
                    >
                      <b>
                        {tag_number && tag_number !== "-" ? tag_number : "N/A"}
                      </b>
                    </p>
                  </div>
                </div>

                <div className="editPetDetails-form-container-bottom mt-3">
                  <p style={{ marginLeft: "4rem" }}>
                    <strong>Pet Details</strong>
                  </p>
                  <form
                    className="editPetDetails-form"
                    onSubmit={this.submitHandler}
                  >
                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          variant="outlined"
                          select
                          label="Select Pet Type"
                          value={valuable_type}
                          onChange={this.handleDropdownChange}
                          required
                        >
                          <MenuItem value="Cat">Cat</MenuItem>
                          <MenuItem value="Dog">Dog</MenuItem>
                        </TextField>
                      </div>

                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          label="Pet Name"
                          type="text"
                          variant="outlined"
                          name="pet_name"
                          value={pet_name}
                          onChange={this.changeHandler}
                          inputProps={{ maxLength: 12 }}
                          required
                        />
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        {!valuable_type ? (
                          <TextField
                            variant="outlined"
                            select
                            label="Pet Breed"
                            value={pet_breed}
                            onChange={this.changeHandler}
                            inputProps={{ maxLength: 12 }}
                            required
                          >
                            <MenuItem defaultValue disabled hidden></MenuItem>
                          </TextField>
                        ) : (
                          <div></div>
                        )}

                        {valuable_type === "Dog" ? (
                          <>
                            <TextField
                              select
                              label="Pet Breed"
                              name="pet_breed"
                              value={pet_breed}
                              onChange={this.changeHandler}
                              variant="outlined"
                            >
                              <MenuItem hidden></MenuItem>
                              <MenuItem value="Affenpinscher">
                                Affenpinscher
                              </MenuItem>
                              <MenuItem value="Afghan Hound">
                                Afghan Hound
                              </MenuItem>
                              <MenuItem value="Airedale Terrier">
                                Airedale Terrier
                              </MenuItem>
                              <MenuItem value="Akita">Akita</MenuItem>
                              <MenuItem value="Alaskan Malamute">
                                Alaskan Malamute
                              </MenuItem>
                              <MenuItem value="AmericanPitbull">
                                American Pit bull
                              </MenuItem>
                              <MenuItem value="American Staffordshire Terrier">
                                American Staffordshire Terrier
                              </MenuItem>
                              <MenuItem value="American Water Spaniel">
                                American Water Spaniel
                              </MenuItem>
                              <MenuItem value="Australian Cattle Dog">
                                Australian Cattle Dog
                              </MenuItem>
                              <MenuItem value="Australian Shepherd">
                                Australian Shepherd
                              </MenuItem>
                              <MenuItem value="Australian Terrier">
                                Australian Terrier
                              </MenuItem>
                              <MenuItem value="Basenji">Basenji</MenuItem>
                              <MenuItem value="Basset Hound">
                                Basset Hound
                              </MenuItem>
                              <MenuItem value="Beagle">Beagle</MenuItem>
                              <MenuItem value="Bearded Collie">
                                Bearded Collie
                              </MenuItem>
                              <MenuItem value="Bedlington Terrier">
                                Bedlington Terrier
                              </MenuItem>
                              <MenuItem value="Bernese Mountain Dog">
                                Bernese Mountain Dog
                              </MenuItem>
                              <MenuItem value="Bichon Frise">
                                Bichon Frise
                              </MenuItem>
                              <MenuItem value="Black And Tan Coonhound">
                                Black And Tan Coonhound
                              </MenuItem>
                              <MenuItem value="Bloodhound">Bloodhound</MenuItem>
                              <MenuItem value="Border Collie">
                                Border Collie
                              </MenuItem>
                              <MenuItem value="Border Terrier">
                                Border Terrier
                              </MenuItem>
                              <MenuItem value="Borzoi">Borzoi</MenuItem>
                              <MenuItem value="Boston Terrier">
                                Boston Terrier
                              </MenuItem>
                              <MenuItem value="Bouvier Des Flandres">
                                Bouvier Des Flandres
                              </MenuItem>
                              <MenuItem value="Boxer">Boxer</MenuItem>
                              <MenuItem value="Briard">Briard</MenuItem>
                              <MenuItem value="Brittany">Brittany</MenuItem>
                              <MenuItem value="Brussels Griffon">
                                Brussels Griffon
                              </MenuItem>
                              <MenuItem value="Bull Terrier">
                                Bull Terrier
                              </MenuItem>
                              <MenuItem value="Bulldog">Bulldog</MenuItem>
                              <MenuItem value="Bullmastiff">
                                Bullmastiff
                              </MenuItem>
                              <MenuItem value="Cairn Terrier">
                                Cairn Terrier
                              </MenuItem>
                              <MenuItem value="Canaan Dog">Canaan Dog</MenuItem>
                              <MenuItem value="Chesapeake Bay Retriever">
                                Chesapeake Bay Retriever
                              </MenuItem>
                              <MenuItem value="Chihuahua">Chihuahua</MenuItem>
                              <MenuItem value="Chinese Crested">
                                Chinese Crested
                              </MenuItem>
                              <MenuItem value="Chinese Shar-Pei">
                                Chinese Shar-Pei
                              </MenuItem>
                              <MenuItem value="Chow Chow">Chow Chow</MenuItem>
                              <MenuItem value="Clumber Spaniel">
                                Clumber Spaniel
                              </MenuItem>
                              <MenuItem value="Cocker Spaniel">
                                Cocker Spaniel
                              </MenuItem>
                              <MenuItem value="Collie">Collie</MenuItem>
                              <MenuItem value="Cross Breed">
                                Cross Breed
                              </MenuItem>
                              <MenuItem value="Curly-Coated Retriever">
                                Curly-Coated Retriever
                              </MenuItem>
                              <MenuItem value="Dachshund">Dachshund</MenuItem>
                              <MenuItem value="Dalmatian">Dalmatian</MenuItem>
                              <MenuItem value="Doberman Pinscher">
                                Doberman Pinscher
                              </MenuItem>
                              <MenuItem value="English Cocker Spaniel">
                                English Cocker Spaniel
                              </MenuItem>
                              <MenuItem value="English Setter">
                                English Setter
                              </MenuItem>
                              <MenuItem value="English Springer Spaniel">
                                English Springer Spaniel
                              </MenuItem>
                              <MenuItem value="English Toy Spaniel">
                                English Toy Spaniel
                              </MenuItem>
                              <MenuItem value="Eskimo Dog">Eskimo Dog</MenuItem>
                              <MenuItem value="Finnish Spitz">
                                Finnish Spitz
                              </MenuItem>
                              <MenuItem value="Flat-Coated Retriever">
                                Flat-Coated Retriever
                              </MenuItem>
                              <MenuItem value="Fox Terrier">
                                Fox Terrier
                              </MenuItem>
                              <MenuItem value="Foxhound">Foxhound</MenuItem>
                              <MenuItem value="French Bulldog">
                                French Bulldog
                              </MenuItem>
                              <MenuItem value="German Shepherd">
                                German Shepherd
                              </MenuItem>
                              <MenuItem value="German Shorthaired Pointer">
                                German Shorthaired Pointer
                              </MenuItem>
                              <MenuItem value="German Wirehaired Pointer">
                                German Wirehaired Pointer
                              </MenuItem>
                              <MenuItem value="Golden Retriever">
                                Golden Retriever
                              </MenuItem>
                              <MenuItem value="Gordon Setter">
                                Gordon Setter
                              </MenuItem>
                              <MenuItem value="Great Dane">Great Dane</MenuItem>
                              <MenuItem value="Greyhound">Greyhound</MenuItem>
                              <MenuItem value="Indian Paraigh Dog">
                                Indian Paraigh Dog
                              </MenuItem>
                              <MenuItem value="Irish Setter">
                                Irish Setter
                              </MenuItem>
                              <MenuItem value="Irish Water Spaniel">
                                Irish Water Spaniel
                              </MenuItem>
                              <MenuItem value="Irish Wolfhound">
                                Irish Wolfhound
                              </MenuItem>
                              <MenuItem value="Jack Russell Terrier">
                                Jack Russell Terrier
                              </MenuItem>
                              <MenuItem value="Japanese Spaniel">
                                Japanese Spaniel
                              </MenuItem>
                              <MenuItem value="Keeshond">Keeshond</MenuItem>
                              <MenuItem value="Kerry Blue Terrier">
                                Kerry Blue Terrier
                              </MenuItem>
                              <MenuItem value="Komondor">Komondor</MenuItem>
                              <MenuItem value="Kuvasz">Kuvasz</MenuItem>
                              <MenuItem value="Labrador">Labrador</MenuItem>
                              <MenuItem value="Labrador Retriever">
                                Labrador Retriever
                              </MenuItem>
                              <MenuItem value="Lakeland Terrier">
                                Lakeland Terrier
                              </MenuItem>
                              <MenuItem value="Lhasa Apso">Lhasa Apso</MenuItem>
                              <MenuItem value="Maltese">Maltese</MenuItem>
                              <MenuItem value="Manchester Terrier">
                                Manchester Terrier
                              </MenuItem>
                              <MenuItem value="Mastiff">Mastiff</MenuItem>
                              <MenuItem value="Mexican Hairless">
                                Mexican Hairless
                              </MenuItem>
                              <MenuItem value="Mixed Breed">
                                Mixed Breed
                              </MenuItem>
                              <MenuItem value="Mongreal">Mongreal</MenuItem>
                              <MenuItem value="Mudhol">Mudhol</MenuItem>
                              <MenuItem value="Newfoundland">
                                Newfoundland
                              </MenuItem>
                              <MenuItem value="Norwegian Elkhound">
                                Norwegian Elkhound
                              </MenuItem>
                              <MenuItem value="Norwich Terrier">
                                Norwich Terrier
                              </MenuItem>
                              <MenuItem value="Otterhound">Otterhound</MenuItem>
                              <MenuItem value="Papillon">Papillon</MenuItem>
                              <MenuItem value="Pekingese">Pekingese</MenuItem>
                              <MenuItem value="Pointer">Pointer</MenuItem>
                              <MenuItem value="Poodle">Poodle</MenuItem>
                              <MenuItem value="Pomeranian">Pomeranian</MenuItem>
                              <MenuItem value="Pug">Pug</MenuItem>
                              <MenuItem value="Puli">Puli</MenuItem>
                              <MenuItem value="Rhodesian Ridgeback">
                                Rhodesian Ridgeback
                              </MenuItem>
                              <MenuItem value="Rottweiler">Rottweiler</MenuItem>
                              <MenuItem value="Saint Bernard">
                                Saint Bernard
                              </MenuItem>
                              <MenuItem value="Saluki">Saluki</MenuItem>
                              <MenuItem value="Samoyed">Samoyed</MenuItem>
                              <MenuItem value="Schipperke">Schipperke</MenuItem>
                              <MenuItem value="Schnauzer">Schnauzer</MenuItem>
                              <MenuItem value="Scottish Deerhound">
                                Scottish Deerhound
                              </MenuItem>
                              <MenuItem value="Scottish Terrier">
                                Scottish Terrier
                              </MenuItem>
                              <MenuItem value="Sealyham Terrier">
                                Sealyham Terrier
                              </MenuItem>
                              <MenuItem value="Shetland Sheepdog">
                                Shetland Sheepdog
                              </MenuItem>
                              <MenuItem value="Shihtzu">Shihtzu</MenuItem>
                              <MenuItem value="Siberian Husky">
                                Siberian Husky
                              </MenuItem>
                              <MenuItem value="Silky Terrier">
                                Silky Terrier
                              </MenuItem>
                              <MenuItem value="Skye Terrier">
                                Skye Terrier
                              </MenuItem>
                              <MenuItem value="Staffordshire Bull Terrier">
                                Staffordshire Bull Terrier
                              </MenuItem>
                              <MenuItem value="Soft-Coated Wheaten Terrier">
                                Soft-Coated Wheaten Terrier
                              </MenuItem>
                              <MenuItem value="Sussex Spaniel">
                                Sussex Spaniel
                              </MenuItem>
                              <MenuItem value="Spitz">Spitz</MenuItem>
                              <MenuItem value="Tibetan Terrier">
                                Tibetan Terrier
                              </MenuItem>
                              <MenuItem value="Vizsla">Vizsla</MenuItem>
                              <MenuItem value="Weimaraner">Weimaraner</MenuItem>
                              <MenuItem value="Welsh Terrier">
                                Welsh Terrier
                              </MenuItem>
                              <MenuItem value="West Highland White Terrier">
                                West Highland White Terrier
                              </MenuItem>
                              <MenuItem value="Whippet">Whippet</MenuItem>
                              <MenuItem value="Yorkshire Terrier">
                                Yorkshire Terrier
                              </MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </>
                        ) : (
                          <div></div>
                        )}

                        {valuable_type === "Cat" ? (
                          <>
                            <TextField
                              // className="editPetDetails-select-tag"
                              select
                              label="Pet Breed"
                              name="pet_breed"
                              value={pet_breed}
                              onChange={this.changeHandler}
                              variant="outlined"
                            >
                              <MenuItem hidden></MenuItem>
                              <MenuItem value="Abyssinian">Abyssinian</MenuItem>
                              <MenuItem value="American Bobtail">
                                American Bobtail
                              </MenuItem>
                              <MenuItem value="American Curl">
                                American Curl
                              </MenuItem>
                              <MenuItem value="American Shorthair">
                                American Shorthair
                              </MenuItem>
                              <MenuItem value="American Wirehair">
                                American Wirehair
                              </MenuItem>
                              <MenuItem value="Balinese">Balinese</MenuItem>
                              <MenuItem value="Bengal">Bengal</MenuItem>
                              <MenuItem value="Birman">Birman</MenuItem>
                              <MenuItem value="Bombay">Bombay</MenuItem>
                              <MenuItem value="British Shorthair">
                                British Shorthair
                              </MenuItem>
                              <MenuItem value="Burmese">Burmese</MenuItem>
                              <MenuItem value="Chartreux">Chartreux</MenuItem>
                              <MenuItem value="Chausie">Chausie</MenuItem>
                              <MenuItem value="Cornish Rex">
                                Cornish Rex
                              </MenuItem>
                              <MenuItem value="Devon Rex">Devon Rex</MenuItem>
                              <MenuItem value="Donskoy">Donskoy</MenuItem>
                              <MenuItem value="Egyptian Mau">
                                Egyptian Mau
                              </MenuItem>
                              <MenuItem value="Exotic Shorthair">
                                Exotic Shorthair
                              </MenuItem>
                              <MenuItem value="Havana">Havana</MenuItem>
                              <MenuItem value="Highlander">Highlander</MenuItem>
                              <MenuItem value="Himalayan">Himalayan</MenuItem>
                              <MenuItem value="Household Pet Cat">
                                Household Pet Cat
                              </MenuItem>
                              <MenuItem value="Household Pet Kitten">
                                Household Pet Kitten
                              </MenuItem>
                              <MenuItem value="Japanese Bobtail">
                                Japanese Bobtail
                              </MenuItem>
                              <MenuItem value="Korat">Korat</MenuItem>
                              <MenuItem value="Kurilian Bobtail">
                                Kurilian Bobtail
                              </MenuItem>
                              <MenuItem value="Laperm">Laperm</MenuItem>
                              <MenuItem value="Maine Coon">Maine Coon</MenuItem>
                              <MenuItem value="Manx">Manx</MenuItem>
                              <MenuItem value="Minskin">Minskin</MenuItem>
                              <MenuItem value="Munchkin">Munchkin</MenuItem>
                              <MenuItem value="Nebelung">Nebelung</MenuItem>
                              <MenuItem value="Norwegian Forest Cat">
                                Norwegian Forest Cat
                              </MenuItem>
                              <MenuItem value="Ocicat">Ocicat</MenuItem>
                              <MenuItem value="Ojos Azules">
                                Ojos Azules
                              </MenuItem>
                              <MenuItem value="Oriental">Oriental</MenuItem>
                              <MenuItem value="Persian">Persian</MenuItem>
                              <MenuItem value="Peterbald">Peterbald</MenuItem>
                              <MenuItem value="Pixiebob">Pixiebob</MenuItem>
                              <MenuItem value="Ragdoll">Ragdoll</MenuItem>
                              <MenuItem value="Russian Blue">
                                Russian Blue
                              </MenuItem>
                              <MenuItem value="Savannah">Savannah</MenuItem>
                              <MenuItem value="Scottish Fold">
                                Scottish Fold
                              </MenuItem>
                              <MenuItem value="Selkirk Rex">
                                Selkirk Rex
                              </MenuItem>
                              <MenuItem value="Serengeti">Serengeti</MenuItem>
                              <MenuItem value="Siberian">Siberian</MenuItem>
                              <MenuItem value="Siamese">Siamese</MenuItem>
                              <MenuItem value="Singapura">Singapura</MenuItem>
                              <MenuItem value="Snowshoe">Snowshoe</MenuItem>
                              <MenuItem value="Sokoke">Sokoke</MenuItem>
                              <MenuItem value="Somali">Somali</MenuItem>
                              <MenuItem value="Sphynx">Sphynx</MenuItem>
                              <MenuItem value="Thai">Thai</MenuItem>
                              <MenuItem value="Tonkinese">Tonkinese</MenuItem>
                              <MenuItem value="Toyger">Toyger</MenuItem>
                              <MenuItem value="Turkish Angora">
                                Turkish Angora
                              </MenuItem>
                              <MenuItem value="Turkish Van">
                                Turkish Van
                              </MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </>
                        ) : null}

                        {pet_breed === "Other" ? (
                          <TextField
                            type="text"
                            className="mt-2"
                            inputProps={{ maxLength: 12 }}
                            value={pet_breedother}
                            onChange={this.changeHandler}
                            name="pet_breedother"
                            label="Other Pet Breed"
                            variant="outlined"
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>

                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          select
                          label="Pet Color"
                          name="pet_color"
                          value={pet_color}
                          inputProps={{ maxLength: 6 }}
                          onChange={this.changeHandler}
                          variant="outlined"
                          required
                        >
                          <MenuItem value="white">White</MenuItem>
                          <MenuItem value="brown">Brown</MenuItem>
                          <MenuItem value="black">Black</MenuItem>
                          <MenuItem value="beige">Beige</MenuItem>
                          <MenuItem value="golden">Golden</MenuItem>
                          <MenuItem value="grey">Grey</MenuItem>
                          <MenuItem value="chocolate">Chocolate</MenuItem>
                          <MenuItem value="silver">Silver</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </TextField>
                        {pet_color === "Other" ? (
                          <TextField
                            type="text"
                            variant="outlined"
                            label="Pet Other Color"
                            className="mt-2"
                            inputProps={{ maxLength: 6 }}
                            value={pet_colorother}
                            onChange={this.changeHandler}
                            name="pet_colorother"
                            required
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div
                      className="two-field-wrapper"
                      // style={{ marginBottom: "-10px" }}
                    >
                      <div className="editPetDetails-input-wrapper">
                        {!valuable_type ? (
                          <TextField
                            select
                            label="Blood Group"
                            value={pet_breed}
                            onChange={this.changeHandler}
                            variant="outlined"
                          >
                            <MenuItem disabled hidden></MenuItem>
                          </TextField>
                        ) : (
                          <div></div>
                        )}

                        {valuable_type === "Dog" ? (
                          <>
                            <TextField
                              select
                              label="Blood Group"
                              name="pet_bloodgrp"
                              value={pet_bloodgrp}
                              onChange={this.changeHandler}
                              variant="outlined"
                            >
                              <MenuItem hidden></MenuItem>
                              <MenuItem value="dea1.1+">DEA-1.1 +</MenuItem>
                              <MenuItem value="dea1.1-">DEA-1.1 -</MenuItem>
                              <MenuItem value="dea1.2+">DEA-1.2 +</MenuItem>
                              <MenuItem value="dea1.2-">DEA-1.2 -</MenuItem>
                              <MenuItem value="dea1.3+">DEA-3 +</MenuItem>
                              <MenuItem value="dea1.3-">DEA-3 -</MenuItem>
                              <MenuItem value="dea1.4+">DEA-4 +</MenuItem>
                              <MenuItem value="dea1.4-">DEA-4 -</MenuItem>
                              <MenuItem value="dea1.5+">DEA-5 +</MenuItem>
                              <MenuItem value="dea1.5-">DEA-5 -</MenuItem>
                              <MenuItem value="dea1.7+">DEA-7 +</MenuItem>
                              <MenuItem value="dea1.7-">DEA-7 -</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </>
                        ) : (
                          <div></div>
                        )}

                        {valuable_type === "Cat" ? (
                          <>
                            <TextField
                              select
                              label="Blood Group"
                              name="pet_bloodgrp"
                              value={pet_bloodgrp}
                              onChange={this.changeHandler}
                              variant="outlined"
                            >
                              <MenuItem hidden></MenuItem>
                              <MenuItem value="a+">A+</MenuItem>
                              <MenuItem value="a-">A-</MenuItem>
                              <MenuItem value="b+">B+</MenuItem>
                              <MenuItem value="b-">B-</MenuItem>
                              <MenuItem value="ab+">AB+</MenuItem>
                              <MenuItem value="ab-">AB-</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </>
                        ) : (
                          <div></div>
                        )}

                        {pet_bloodgrp === "Other" ? (
                          <TextField
                            type="text"
                            className="mt-2 mb-3"
                            maxlength="20"
                            value={pet_bloodgrpother}
                            onChange={this.changeHandler}
                            name="pet_bloodgrpother"
                            variant="outlined"
                            label="Other Pet Blood Group"
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>

                      <div className="editPetDetails-input-wrapper">
                        <FormControl>
                          <FormLabel className="gender-lebal">
                            Gender *
                          </FormLabel>
                          <RadioGroup
                            aria-label="pet_gender"
                            name="pet_gender"
                            value={pet_gender}
                            onChange={this.changeHandler}
                            required
                            row
                          >
                            <FormControlLabel
                              value="male"
                              control={<RadioButton />}
                              label="Male"
                            />

                            <FormControlLabel
                              value="female"
                              control={<RadioButton />}
                              label="Female"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        {/* <span className="pet-birth-span">Date of Birth</span> */}

                        {/* <input
                        type="date"
                        className="editPetDetails-input-tag"
                        max={dtoday}
                        value={pet_dob}
                        onChange={this.changeHandlerdate}
                        name="pet_dob"
                      /> */}

                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker", "DatePicker"]}
                        >
                          <DatePicker
                            label="Date of Birth"
                            name="pet_dob"
                            defaultValue={dayjs(pet_dob)}
                            value={dayjs(pet_dob)}
                            onChange={this.changeHandlerdate}
                          />
                        </DemoContainer>
                      </LocalizationProvider> */}

                        <TextField
                          label="Date of Birth"
                          variant="outlined"
                          type="date"
                          name="pet_dob"
                          value={pet_dob}
                          onChange={this.changeHandlerdate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            max: today,
                          }}
                        />
                      </div>

                      <div
                        className="editPetDetails-input-wrapper"
                        id="select-pet-age"
                      >
                        <TextField
                          label="Age"
                          type="number"
                          variant="outlined"
                          // className="editPetDetails-input-tag"
                          value={pet_age}
                          onChange={this.changeHandler}
                          name="pet_age"
                        />
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          label="Identification Mark"
                          variant="outlined"
                          type="text"
                          // className="editPetDetails-input-tag"
                          inputProps={{ maxLength: 35 }}
                          value={pet_ident}
                          onChange={this.changeHandler}
                          name="pet_ident"
                          required
                        />
                      </div>

                      <div
                        className="editPetDetails-input-wrapper"
                        id="select-pet-micno"
                      >
                        <TextField
                          label="Microchip Number"
                          variant="outlined"
                          type="number"
                          // className="editPetDetails-input-tag"
                          value={pet_microchip}
                          onChange={this.changeHandler}
                          name="pet_microchip"
                        />
                      </div>
                    </div>

                    <div className="editPetDetails-btn-row">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ width: "100%" }}
                        className="submit-login bg-warning text-white mt-2"
                      >
                        {this.state.redirecttopetparent ? "NEXT" : "SUBMIT"}
                        {/* {this.state.redirecttopetparent ? "SUBMIT" : "NEXT"} */}
                      </Button>

                      <Button
                        type="button"
                        variant="contained"
                        style={{ width: "100%" }}
                        className="bg-white mt-2 cancel-btn submit-login "
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>

                    {this.state.showLoader && (
                      <div className="loader-container">
                        <div className="loader">
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                            }
                            alt="Loading..."
                            loading="lazy"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>
                    )}
                    {/* <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={this.state.snackbaropen}
                      autoHideDuration={8000}
                      onClose={this.snackbarClose}
                      message={this.state.snackbarmsg}
                      action={[
                        <IconButton
                          arial-label="Close"
                          color="inherit"
                          onClick={this.snackbarClose}
                        >
                          X
                        </IconButton>,
                      ]}
                    /> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button onClick={this.handelimagecrop}>Submit</Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>
      </>
    );
  }
}
