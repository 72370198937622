import React from "react";
import "../medicaldashboard/medicaldashboard.component.css";
import Button from "@material-ui/core/Button";
import Vaccinationcard from "./cards/vaccination.component.jsx";
import Allergycard from "./cards/allergy.component.jsx";
import Surgerycard from "./cards/surgery.component.jsx";
import Medicationcard from "./cards/medication.component.jsx";
import Medicalconditioncard from "./cards/medicalcondition.component.jsx";
import Measurementcard from "./cards/measurement.component.jsx";
import axios from "axios";
import AddNewRecord from "./add-new-record/addnewrecord.component.jsx";
import Dialog from "@material-ui/core/Dialog";
import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { Fab, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { switchUrls } from "../../api/index.js";

const baseURL = switchUrls("genie");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

let usertoken = readCookie("token");
const token = usertoken;

export default class MedicalDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebar: true,
      subMenu: true,
      AllergyList: [],
      MedicationList: [],
      MedicalConList: [],
      MeasureList: [],
      SurgeryList: [],
      VaccineList: [],
      AllergyDateList: [],
      MedicationDateList: [],
      MedicalConDateList: [],
      MeasureDateList: [],
      SurgeryDateList: [],
      VaccineDateList: [],
      medicaldetails: null,
      vaccinationcount: null,
      allergycount: null,
      surgerycount: null,
      medicationcount: null,
      medicalconditioncount: null,
      measurementcount: null,
      MainPopup: "",
      filteroption: "filter",
      addnewrecord: false,
      displaydefaultmsg: false,
      sortType: "",
      selected: false,
      sortbyAD: false,
      togglebtn: false,
      togglebtnname: false,
      pet_imagelocation: null,
      pet_imagename: null,
      searchData: "",
      isFloatingBtn: true,
      showSearchInput: false,
      showLoader: true,
    };

    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }

  search = (e) => {
    let data = e.target.value.toLowerCase();
    let list1 = this.state.medicaldetails.Vaccination.filter((product) => {
      if (
        product.VaccinationDoctor_name.toLowerCase().includes(data) ||
        product.Vaccination_name.toLowerCase().includes(data) ||
        product.VaccinationNotes.toLowerCase().includes(data)
      )
        return true;
    });

    this.setState({ VaccineList: list1 });

    let list2 = this.state.medicaldetails.Medication.filter((product) => {
      if (
        product.Medication_name.toLowerCase().includes(data) ||
        product.MedicationDoctor_name.toLowerCase().includes(data) ||
        product.MedicationNotes.toLowerCase().includes(data)
      )
        return true;
    });

    this.setState({ MedicationList: list2 });

    let list3 = this.state.medicaldetails.Allergy.filter((product) => {
      if (
        product.Allergy_name.toLowerCase().includes(data) ||
        product.AllergyDoctor_name.toLowerCase().includes(data) ||
        product.AllergyNotes.toLowerCase().includes(data)
      )
        return true;
    });

    this.setState({ AllergyList: list3 });

    let list4 = this.state.medicaldetails.Medicalcondition.filter((product) => {
      if (
        product.Medicalcondition_type.toLowerCase().includes(data) ||
        product.MedicalconditionDoctor_name.toLowerCase().includes(data) ||
        product.MedicalconditionNotes.toLowerCase().includes(data)
      )
        return true;
    });

    this.setState({ MedicalConList: list4 });

    let list5 = this.state.medicaldetails.Measurement.filter((product) => {
      if (
        product.MeasurementNotes.toLowerCase().includes(data) ||
        product.Measuredby.toLowerCase().includes(data)
      )
        return true;
    });

    this.setState({ MeasureList: list5 });

    let list6 = this.state.medicaldetails.Surgery.filter((product) => {
      if (
        product.Surgery_name.toLowerCase().includes(data) ||
        product.SurgeryDoctor_name.toLowerCase().includes(data) ||
        product.SurgeryNotes.toLowerCase().includes(data)
      )
        return true;
    });

    this.setState({ SurgeryList: list6 });
  };

  onNameSort = (sortType) => {
    this.setState({ sortType });
    this.setState({ selected: true });
    this.setState({ sortbyAD: !this.state.sortbyAD });
    this.setState({ togglebtnname: !this.state.togglebtnname });
  };

  onAppointSort = (sortType) => {
    this.setState({ sortType });
    this.setState({ selected: true });
    this.setState({ sortbyAD: !this.state.sortbyAD });
    this.setState({ togglebtn: !this.state.togglebtn });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ selected: false });
  };

  handleCloseRecord = (e) => {
    this.setState({ addnewrecord: false });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  date_sort_asc = function (date1, date2) {
    if (date1 > date2) return 1;
    if (date1 < date2) return -1;

    return 0;
  };

  componentDidMount() {
    localStorage.setItem("tag_number", this.props.match.params.tag_number);

    axios
      .get(
        `${baseURL}/api/pet/getMedicalDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        // console.log("medical details : ", response);
        // console.log("get medical details : ", response.data[0]);

        // console.log(
        //   "get medical details : ",
        //   response.data[0].Vaccination[0].Vaccination_ReportsNames[0]
        // );

        response.data.map((res) => {
          this.setState({ medicaldetails: res });
          // fOr Allergy---------------------------------
          res.Allergy.sort((a, b) => {
            let Aa = a.Allergy_name.toLowerCase();
            let Ab = b.Allergy_name.toLowerCase();

            if (Aa < Ab) {
              return -1;
            }

            if (Aa > Ab) {
              return 1;
            }

            return 0;
          });

          this.setState({ AllergyList: res.Allergy });

          // date sorting start

          const temp_AllergyDateList = [];

          res.Allergy.map((res) => {
            temp_AllergyDateList.push(res);
          });

          temp_AllergyDateList.map((resposi) => {
            resposi.AllergyDiagnosis_date = new Date(
              resposi.AllergyDiagnosis_date
            );
          });

          temp_AllergyDateList.map((repd) => {});

          temp_AllergyDateList.sort((date1, date2) => {
            if (date1.AllergyDiagnosis_date > date2.AllergyDiagnosis_date)
              return 1;

            if (date1.AllergyDiagnosis_date < date2.AllergyDiagnosis_date)
              return -1;

            return 0;
          });

          this.setState({ AllergyDateList: temp_AllergyDateList });

          //date sorting end

          //   Allergy End--------------------------------

          // For Medicaton-------------------------------
          res.Medication.sort((a, b) => {
            let Aa = a.Medication_name.toLowerCase();
            let Ab = b.Medication_name.toLowerCase();

            if (Aa < Ab) {
              return -1;
            }

            if (Aa > Ab) {
              return 1;
            }
            return 0;
          });

          this.setState({ MedicationList: res.Medication });

          // Sorting for date

          const temp_MedicationDateList = [];

          res.Medication.map((res) => {
            temp_MedicationDateList.push(res);
          });

          temp_MedicationDateList.map((resposi) => {
            resposi.Medication_startdate = new Date(
              resposi.Medication_startdate
            );
          });

          temp_MedicationDateList.map((repd) => {});

          temp_MedicationDateList.sort((date1, date2) => {
            if (date1.Medication_startdate > date2.Medication_startdate)
              return 1;

            if (date1.Medication_startdate < date2.Medication_startdate)
              return -1;

            return 0;
          });

          this.setState({ MedicationDateList: temp_MedicationDateList });

          // Sort Date end

          //Medication end ------------------------------------

          // For Medical Condition--------------------------
          res.Medicalcondition.sort((a, b) => {
            let Aa = a.MedicalconditionTitle.toLowerCase();
            let Ab = b.MedicalconditionTitle.toLowerCase();

            if (Aa < Ab) {
              return -1;
            }

            if (Aa > Ab) {
              return 1;
            }

            return 0;
          });

          this.setState({ MedicalConList: res.Medicalcondition });
          const temp_MedicalConDateList = [];

          res.Medicalcondition.map((res) => {
            temp_MedicalConDateList.push(res);
          });

          temp_MedicalConDateList.map((resposi) => {
            resposi.MedicalconditionDiagnosed_date = new Date(
              resposi.MedicalconditionDiagnosed_date
            );
          });

          temp_MedicalConDateList.map((repd) => {});

          temp_MedicalConDateList.sort((date1, date2) => {
            if (
              date1.MedicalconditionDiagnosed_date >
              date2.MedicalconditionDiagnosed_date
            )
              return 1;

            if (
              date1.MedicalconditionDiagnosed_date <
              date2.MedicalconditionDiagnosed_date
            )
              return -1;

            return 0;
          });

          this.setState({ MedicalConDateList: temp_MedicalConDateList });

          //date sorting end

          // For Medical Condition End--------------------------

          // For Measurement --------------------------

          res.Measurement.sort((a, b) => {
            let Aa = a.MeasurementTitle.toLowerCase();
            let Ab = b.MeasurementTitle.toLowerCase();

            if (Aa < Ab) {
              return -1;
            }

            if (Aa > Ab) {
              return 1;
            }

            return 0;
          });

          this.setState({ MeasureList: res.Measurement });

          const temp_MeasureDateList = [];

          res.Measurement.map((res) => {
            temp_MeasureDateList.push(res);
          });

          temp_MeasureDateList.map((resposi) => {
            resposi.Measurement_date = new Date(resposi.Measurement_date);
          });

          temp_MeasureDateList.map((repd) => {});

          temp_MeasureDateList.sort((date1, date2) => {
            if (date1.Measurement_date > date2.Measurement_date) return 1;

            if (date1.Measurement_date < date2.Measurement_date) return -1;

            return 0;
          });

          this.setState({ MeasureDateList: temp_MeasureDateList });

          // For Measurement End --------------------------

          // For Surgery --------------------------

          res.Surgery.sort((a, b) => {
            let Aa = a.Surgery_name.toLowerCase();
            let Ab = b.Surgery_name.toLowerCase();

            if (Aa < Ab) {
              return -1;
            }

            if (Aa > Ab) {
              return 1;
            }

            return 0;
          });

          this.setState({ SurgeryList: res.Surgery });

          // date sorting start

          const temp_SurgeryDateList = [];

          res.Surgery.map((res) => {
            temp_SurgeryDateList.push(res);
          });

          temp_SurgeryDateList.map((resposi) => {
            resposi.Surgery_date = new Date(resposi.Surgery_date);
          });

          temp_SurgeryDateList.map((repd) => {});

          temp_SurgeryDateList.sort((date1, date2) => {
            if (date1.Surgery_date > date2.Surgery_date) return 1;

            if (date1.Surgery_date < date2.Surgery_date) return -1;

            return 0;
          });

          this.setState({ SurgeryDateList: temp_SurgeryDateList });

          //date sorting end

          // For Surgery End--------------------------

          // For Vaccination--------------------------
          res.Vaccination.sort((a, b) => {
            let Aa = a.Vaccination_name.toLowerCase();
            let Ab = b.Vaccination_name.toLowerCase();

            if (Aa < Ab) {
              return -1;
            }

            if (Aa > Ab) {
              return 1;
            }

            return 0;
          });

          this.setState({ VaccineList: res.Vaccination });

          // date sorting start

          const temp_VaccineDateList = [];

          res.Vaccination.map((res) => {
            temp_VaccineDateList.push(res);
          });

          temp_VaccineDateList.map((resposi) => {
            resposi.VaccinationAdministration_date = new Date(
              resposi.VaccinationAdministration_date
            );
          });

          temp_VaccineDateList.map((repd) => {});

          temp_VaccineDateList.sort((date1, date2) => {
            if (
              date1.VaccinationAdministration_date >
              date2.VaccinationAdministration_date
            )
              return 1;

            if (
              date1.VaccinationAdministration_date <
              date2.VaccinationAdministration_date
            )
              return -1;

            return 0;
          });

          this.setState({ VaccineDateList: temp_VaccineDateList });

          //date sorting end

          // For Vaccination End--------------------------
        });
      });
    axios
      .get(
        `${baseURL}/api/pet/countMedicalDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        let count = response.data;

        this.setState({
          vaccinationcount: count.Vaccination,
          allergycount: count.Allergy,
          surgerycount: count.Surgery,
          medicationcount: count.Medication,
          medicalconditioncount: count.Medicalcondition,
          measurementcount: count.Measurement,
        });

        if (
          count.Vaccination === 0 &&
          count.Allergy === 0 &&
          count.Surgery === 0 &&
          count.Medication === 0 &&
          count.Medicalcondition === 0 &&
          count.Measurement === 0
        ) {
          this.setState({ displaydefaultmsg: true });
        } else {
          this.setState({ displaydefaultmsg: false });
        }
      });

    axios
      .get(
        `${baseURL}/api/pet/getPetParentDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        this.setState({
          pet_imagelocation: response.data.pet_imagelocation,
          pet_imagename: response.data.pet_imagename,
        });
        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 1000);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  render() {
    const {
      sidebar,
      subMenu,
      pet_imagelocation,
      pet_imagename,
      togglebtnname,
      togglebtn,
      sortbyAD,
      selected,
      AllergyDateList,
      MedicationDateList,
      MedicalConDateList,
      MeasureDateList,
      SurgeryDateList,
      VaccineDateList,
      MedicationList,
      MedicalConList,
      MeasureList,
      SurgeryList,
      VaccineList,
      mysort,
      sortType,
      AllergyList,
      medicaldetails,
      vaccinationcount,
      allergycount,
      surgerycount,
      medicationcount,
      medicalconditioncount,
      measurementcount,
      filteroption,
      isFloatingBtn,
      showSearchInput,
    } = this.state;

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />

        <div className="main-wrapper">
          <div className="left-wrapper">
            <div className="sidebar-toggle-btn-wrapper">
              <button className="sidebar-toggle-btn" onClick={this.showSidebar}>
                {this.state.sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar ? "sidebar-container-hide" : "sidebar-container"
              }
            >
              <button className="sidebar-menu-link" onClick={this.showSubMenu}>
                MY TAGS{" "}
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={subMenu ? "sidebar-menu-ul-hide" : "sidebar-menu-ul"}
              >
                <li>
                  <button
                    className="sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    All Tags
                  </button>
                </li>

                <li>
                  <button
                    className="sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    Active | Inactive Tags
                  </button>
                </li>

                <li>
                  <button
                    className="sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    Lost | Found Tags
                  </button>
                </li>
              </ul>

              <button
                className="sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/dashboard/" + this.props.match.params.tag_number;
                }}
              >
                ACTIVATE TAG
              </button>

              {/* <button
                className="sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/pet-parent-details/" +
                    this.props.match.params.tag_number;
                }}
              >
                PET PARENT DETAILS
              </button> */}

              <button
                className="sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/pet-details/" + this.props.match.params.tag_number;
                }}
              >
                PET DETAILS
              </button>
              <button
                className="sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>
              {/* <button
                className="sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/medical-dashboard/" +
                    this.props.match.params.tag_number;
                }}
              >
                PET MEDICAL DETAILS
              </button> */}
            </div>
          </div>

          <div className="medicalDashboard-right-wrapper">
            <div
              className="row text-center"
              style={{
                margin: "auto",
                textAlign: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className="rightside-blocks mt-3">
                <div>
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/vaccinated.png"
                    }
                    width="25px"
                    height="25px"
                    className="rightside-blocks-image"
                    alt="vaccination"
                  />

                  <span className="ml-2 rightside-vaccinations">
                    Vaccinations
                  </span>
                </div>
                <p className="text-center rightside-blocks-middle">
                  {vaccinationcount ? vaccinationcount : 0}
                </p>
              </div>

              <div className="rightside-blocks mt-3">
                <div>
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/allergy.png"
                    }
                    width="25px"
                    height="25px"
                    className="rightside-blocks-image"
                    alt="allergy"
                  />
                  <span className="ml-2 rightside-vaccinations">Allergies</span>
                </div>
                {allergycount ? (
                  <p className="text-center rightside-blocks-middle">
                    {allergycount}
                  </p>
                ) : (
                  <p className="text-center rightside-blocks-middle">0</p>
                )}
              </div>

              <div className="rightside-blocks mt-3">
                <div>
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
                    }
                    width="25px"
                    height="25px"
                    className="rightside-blocks-image"
                    alt="surgery"
                  />
                  <span className="ml-2 rightside-vaccinations">Surgeries</span>
                </div>
                {surgerycount ? (
                  <p className="text-center rightside-blocks-middle">
                    {surgerycount}
                  </p>
                ) : (
                  <p className="text-center rightside-blocks-middle">0</p>
                )}
              </div>

              <div className="rightside-blocks mt-3">
                <div>
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medication.png"
                    }
                    width="25px"
                    height="25px"
                    className="rightside-blocks-image"
                    alt="medication"
                  />
                  <span className="ml-2 rightside-vaccinations">
                    Medication
                  </span>
                </div>
                {medicationcount ? (
                  <p className="text-center rightside-blocks-middle">
                    {medicationcount}
                  </p>
                ) : (
                  <p className="text-center rightside-blocks-middle">0</p>
                )}
              </div>

              <div className="rightside-blocks mt-3">
                <div>
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medical-condition.png"
                    }
                    width="25px"
                    height="25px"
                    className="rightside-blocks-image"
                    alt="medical condition"
                  />
                  <span className="ml-2 rightside-vaccinations">
                    Medical Condition
                  </span>
                </div>
                {medicalconditioncount ? (
                  <p className="text-center rightside-blocks-middle med_con">
                    {medicalconditioncount}
                  </p>
                ) : (
                  <p className="text-center rightside-blocks-middle med_con ">
                    0
                  </p>
                )}
              </div>

              <div className="rightside-blocks mt-3">
                <div>
                  <img
                    src={
                      "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
                    }
                    width="25px"
                    height="25px"
                    className="rightside-blocks-image"
                    alt="measurement"
                  />
                  <span className="ml-2 rightside-vaccinations">
                    Measurement
                  </span>
                </div>
                <p className="rightside-blocks-middle">
                  {measurementcount ? measurementcount : 0}
                </p>
              </div>
            </div>

            <div className="">
              <div className="search-sort-container">
                {/* <div className="sort-filter-div">
                  <div className="dropdown" style={{ fontWeight: "450" }}>
                    Sort
                    <i className="fa fa-caret-down ml-3" aria-hidden="true"></i>
                    <div
                      className="dropdown-content py-1 px-2"
                      style={{
                        "margin-top": "-0px",
                        marginLeft: "-40px",
                        padding: "10px",
                        width: "250px",
                      }}
                    >
                      <button
                        onClick={() => {
                          this.onNameSort("name");
                        }}
                        className="btn dropdown-item-1 text-center"
                        style={{
                          height: "30px",
                          fontSize: "15px",
                          padding: "3px",
                        }}
                      >
                        By Name
                        <span>
                          <i
                            className={
                              !this.state.togglebtnname
                                ? "fa fa-arrow-up ml-2"
                                : ""
                            }
                          ></i>

                          <i
                            className={
                              this.state.togglebtnname
                                ? "fa fa-arrow-down ml-2"
                                : ""
                            }
                          ></i>
                        </span>
                      </button>

                      <button
                        onClick={() => {
                          this.onAppointSort("appointment");
                        }}
                        className="btn dropdown-item-1  text-center"
                        style={{
                          height: "30px",
                          fontSize: "15px",
                          padding: "3px",
                        }}
                      >
                        By Appointment Date
                        <span style={{ display: "inline" }}>
                          <i
                            className={
                              this.state.togglebtn
                                ? "fa fa-arrow-down ml-2"
                                : ""
                            }
                          ></i>
                          <i
                            className={
                              !this.state.togglebtn ? "fa fa-arrow-up ml-2" : ""
                            }
                          ></i>
                        </span>
                      </button>
                    </div>
                  </div>

                  <select
                    name="filteroption"
                    style={{
                      fontSize: "16px",
                      fontWeight: "450",
                      width: "5.6rem",
                    }}
                    value={filteroption}
                    onChange={this.changeHandler}
                    className="rightside-button form-control"
                  >
                    <option value="filter" selected className="ftr_opn">
                      Filter
                    </option>

                    <option className=" ftr_opn" value="Vaccination">
                      Vaccination
                    </option>

                    <option className=" ftr_opn" value="Allergy">
                      Allergy
                    </option>

                    <option className=" ftr_opn" value="Surgery">
                      Surgery
                    </option>

                    <option className=" ftr_opn" value="Medication">
                      Medication
                    </option>

                    <option className=" ftr_opn" value="Medicalcondition">
                      Medical Condition
                    </option>

                    <option className=" ftr_opn" value="Measurement">
                      Measurement
                    </option>
                  </select>
                </div>  */}
                <div className="search-addrecord-div">
                  <div
                    className={`${
                      isFloatingBtn ? "search-addrecord-div-hideShow" : ""
                    }`}
                  >
                    <div className="floating-hideShow-btn">
                      <Fab>
                        <AddIcon
                          color="success"
                          onClick={() => {
                            this.setState({ addnewrecord: true });
                          }}
                        />
                      </Fab>
                      <Fab>
                        <SearchIcon
                          color="action"
                          onClick={() => {
                            this.setState({
                              showSearchInput: !showSearchInput,
                            });
                          }}
                        />
                      </Fab>
                      <input
                        className={`medicaldashboard-search-input ${
                          showSearchInput ? "" : "hide-search"
                        }`}
                        placeholder="Search By Name"
                        onChange={(e) => this.search(e)}
                      />
                    </div>

                    {/* 
                  <button
                    className="medicalDashboard-add-new-record-btn"
                    onClick={() => {
                      this.setState({ addnewrecord: true });
                    }}
                  >
                    <MdIcons.MdAddBox />
                    Add New Record
                  </button> */}
                  </div>
                  <div className="floating-btn">
                    <Tooltip
                      title="Add & Search Medical Record"
                      placement="left"
                    >
                      <Fab
                        onClick={() => {
                          this.setState({
                            isFloatingBtn: !isFloatingBtn,
                          });
                        }}
                      >
                        {isFloatingBtn ? (
                          <AddIcon color="primary" />
                        ) : (
                          <CloseIcon
                            color="secondary"
                            onClick={() => {
                              this.setState({
                                showSearchInput: false,
                              });
                            }}
                          />
                        )}
                      </Fab>
                    </Tooltip>
                  </div>
                </div>
              </div>

              {!this.state.displaydefaultmsg ? (
                <div>
                  {filteroption === "Vaccination" ? (
                    <div>
                      {this.state.selected === true &&
                      this.state.sortType === "name" ? (
                        <div>
                          {this.state.sortbyAD === true ? (
                            <div>
                              {VaccineList.map((res) => {
                                return (
                                  <Vaccinationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Vaccination_Reportlocation={
                                      res.Vaccination_Reportlocation
                                    }
                                    Vaccination_Reportname={
                                      res.Vaccination_Reportname
                                    }
                                    Vaccination_ReportsLocations={
                                      res.Vaccination_ReportsLocations
                                    }
                                    Vaccination_ReportsNames={
                                      res.Vaccination_ReportsNames
                                    }
                                    vaccination_id={res.vaccination_id}
                                    Vaccination_name={res.Vaccination_name}
                                    VaccinationDoctor_name={
                                      res.VaccinationDoctor_name
                                    }
                                    VaccinationAdministration_date={
                                      res.VaccinationAdministration_date
                                    }
                                    VaccinationNotes={res.VaccinationNotes}
                                    VaccinationTitle={res.VaccinationTitle}
                                    VaccinationNext={res.VaccinationNext}
                                    VaccinationReminderNotes={
                                      res.VaccinationReminderNotes
                                    }
                                  ></Vaccinationcard>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {VaccineList.reverse().map((res) => {
                                return (
                                  <Vaccinationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Vaccination_Reportlocation={
                                      res.Vaccination_Reportlocation
                                    }
                                    Vaccination_Reportname={
                                      res.Vaccination_Reportname
                                    }
                                    Vaccination_ReportsLocations={
                                      res.Vaccination_ReportsLocations
                                    }
                                    Vaccination_ReportsNames={
                                      res.Vaccination_ReportsNames
                                    }
                                    vaccination_id={res.vaccination_id}
                                    Vaccination_name={res.Vaccination_name}
                                    VaccinationDoctor_name={
                                      res.VaccinationDoctor_name
                                    }
                                    VaccinationAdministration_date={
                                      res.VaccinationAdministration_date
                                    }
                                    VaccinationNotes={res.VaccinationNotes}
                                    VaccinationTitle={res.VaccinationTitle}
                                    VaccinationNext={res.VaccinationNext}
                                    VaccinationReminderNotes={
                                      res.VaccinationReminderNotes
                                    }
                                  ></Vaccinationcard>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.selected === true &&
                      this.state.sortType === "appointment" ? (
                        <div>
                          {
                            //    VaccineDateList.map((res)=>{
                            //     return(<Vaccinationcard tag_number={this.props.match.params.tag_number} Vaccination_Reportlocation={res.Vaccination_Reportlocation} Vaccination_Reportname={res.Vaccination_Reportname} vaccination_id={res.vaccination_id} Vaccination_name={res.Vaccination_name} VaccinationDoctor_name={res.VaccinationDoctor_name} VaccinationAdministration_date={res.VaccinationAdministration_date} VaccinationNotes={res.VaccinationNotes} VaccinationTitle={res.VaccinationTitle} VaccinationNext={res.VaccinationNext}></Vaccinationcard>)

                            //    })
                            this.state.sortbyAD === true ? (
                              <div>
                                {VaccineDateList.map((res) => {
                                  return (
                                    <Vaccinationcard
                                      tag_number={
                                        this.props.match.params.tag_number
                                      }
                                      Vaccination_Reportlocation={
                                        res.Vaccination_Reportlocation
                                      }
                                      Vaccination_Reportname={
                                        res.Vaccination_Reportname
                                      }
                                      Vaccination_ReportsLocations={
                                        res.Vaccination_ReportsLocations
                                      }
                                      Vaccination_ReportsNames={
                                        res.Vaccination_ReportsNames
                                      }
                                      vaccination_id={res.vaccination_id}
                                      Vaccination_name={res.Vaccination_name}
                                      VaccinationDoctor_name={
                                        res.VaccinationDoctor_name
                                      }
                                      VaccinationAdministration_date={
                                        res.VaccinationAdministration_date
                                      }
                                      VaccinationNotes={res.VaccinationNotes}
                                      VaccinationTitle={res.VaccinationTitle}
                                      VaccinationNext={res.VaccinationNext}
                                      VaccinationReminderNotes={
                                        res.VaccinationReminderNotes
                                      }
                                    ></Vaccinationcard>
                                  );
                                })}
                              </div>
                            ) : (
                              <div>
                                {VaccineDateList.reverse().map((res) => {
                                  return (
                                    <Vaccinationcard
                                      tag_number={
                                        this.props.match.params.tag_number
                                      }
                                      Vaccination_Reportlocation={
                                        res.Vaccination_Reportlocation
                                      }
                                      Vaccination_Reportname={
                                        res.Vaccination_Reportname
                                      }
                                      Vaccination_ReportsLocations={
                                        res.Vaccination_ReportsLocations
                                      }
                                      Vaccination_ReportsNames={
                                        res.Vaccination_ReportsNames
                                      }
                                      vaccination_id={res.vaccination_id}
                                      Vaccination_name={res.Vaccination_name}
                                      VaccinationDoctor_name={
                                        res.VaccinationDoctor_name
                                      }
                                      VaccinationAdministration_date={
                                        res.VaccinationAdministration_date
                                      }
                                      VaccinationNotes={res.VaccinationNotes}
                                      VaccinationTitle={res.VaccinationTitle}
                                      VaccinationNext={res.VaccinationNext}
                                      VaccinationReminderNotes={
                                        res.VaccinationReminderNotes
                                      }
                                    ></Vaccinationcard>
                                  );
                                })}
                              </div>
                            )
                          }
                        </div>
                      ) : (
                        ""
                      )}

                      {medicaldetails && this.state.selected === false ? (
                        <div>
                          {medicaldetails.Vaccination.map((res) => {
                            return (
                              <Vaccinationcard
                                tag_number={this.props.match.params.tag_number}
                                Vaccination_Reportlocation={
                                  res.Vaccination_Reportlocation
                                }
                                Vaccination_Reportname={
                                  res.Vaccination_Reportname
                                }
                                Vaccination_ReportsLocations={
                                  res.Vaccination_ReportsLocations
                                }
                                Vaccination_ReportsNames={
                                  res.Vaccination_ReportsNames
                                }
                                vaccination_id={res.vaccination_id}
                                Vaccination_name={res.Vaccination_name}
                                VaccinationDoctor_name={
                                  res.VaccinationDoctor_name
                                }
                                VaccinationAdministration_date={
                                  res.VaccinationAdministration_date
                                }
                                VaccinationNotes={res.VaccinationNotes}
                                VaccinationTitle={res.VaccinationTitle}
                                VaccinationNext={res.VaccinationNext}
                                VaccinationReminderNotes={
                                  res.VaccinationReminderNotes
                                }
                              ></Vaccinationcard>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {filteroption === "Surgery" ? (
                    <div>
                      {this.state.selected === true &&
                      this.state.sortType === "name" ? (
                        <div>
                          {this.state.sortbyAD === true ? (
                            <div>
                              {SurgeryList.map((res) => {
                                console.log("sur: ", res);
                                return (
                                  <Surgerycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Surgery_ReportsLocations={
                                      res.Surgery_ReportsLocations
                                    }
                                    Surgery_ReportsNames={
                                      res.Surgery_ReportsNames
                                    }
                                    Surgery_Reportlocation={
                                      res.Surgery_Reportlocation
                                    }
                                    Surgery_Reportname={res.Surgery_Reportname}
                                    surgery_id={res.surgery_id}
                                    Surgery_name={res.Surgery_name}
                                    SurgeryDoctor_name={res.SurgeryDoctor_name}
                                    Surgery_date={res.Surgery_date}
                                    SurgeryNotes={res.SurgeryNotes}
                                    SurgeryTitle={res.SurgeryTitle}
                                    SurgeryNext={res.SurgeryNext}
                                    SurgeryReminderNotes={
                                      res.SurgeryReminderNotes
                                    }
                                  ></Surgerycard>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {SurgeryList.reverse().map((res) => {
                                return (
                                  <Surgerycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Surgery_ReportsLocations={
                                      res.Surgery_ReportsLocations
                                    }
                                    Surgery_ReportsNames={
                                      res.Surgery_ReportsNames
                                    }
                                    Surgery_Reportlocation={
                                      res.Surgery_Reportlocation
                                    }
                                    Surgery_Reportname={res.Surgery_Reportname}
                                    surgery_id={res.surgery_id}
                                    Surgery_name={res.Surgery_name}
                                    SurgeryDoctor_name={res.SurgeryDoctor_name}
                                    Surgery_date={res.Surgery_date}
                                    SurgeryNotes={res.SurgeryNotes}
                                    SurgeryTitle={res.SurgeryTitle}
                                    SurgeryNext={res.SurgeryNext}
                                    SurgeryReminderNotes={
                                      res.SurgeryReminderNotes
                                    }
                                  ></Surgerycard>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.selected === true &&
                      this.state.sortType === "appointment" ? (
                        <div>
                          {this.state.sortbyAD === true ? (
                            <div>
                              {SurgeryDateList.map((res) => {
                                return (
                                  <Surgerycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Surgery_ReportsLocations={
                                      res.Surgery_ReportsLocations
                                    }
                                    Surgery_ReportsNames={
                                      res.Surgery_ReportsNames
                                    }
                                    Surgery_Reportlocation={
                                      res.Surgery_Reportlocation
                                    }
                                    Surgery_Reportname={res.Surgery_Reportname}
                                    surgery_id={res.surgery_id}
                                    Surgery_name={res.Surgery_name}
                                    SurgeryDoctor_name={res.SurgeryDoctor_name}
                                    Surgery_date={res.Surgery_date}
                                    SurgeryNotes={res.SurgeryNotes}
                                    SurgeryTitle={res.SurgeryTitle}
                                    SurgeryNext={res.SurgeryNext}
                                    SurgeryReminderNotes={
                                      res.SurgeryReminderNotes
                                    }
                                  ></Surgerycard>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {SurgeryDateList.reverse().map((res) => {
                                return (
                                  <Surgerycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Surgery_ReportsLocations={
                                      res.Surgery_ReportsLocations
                                    }
                                    Surgery_ReportsNames={
                                      res.Surgery_ReportsNames
                                    }
                                    Surgery_Reportlocation={
                                      res.Surgery_Reportlocation
                                    }
                                    Surgery_Reportname={res.Surgery_Reportname}
                                    surgery_id={res.surgery_id}
                                    Surgery_name={res.Surgery_name}
                                    SurgeryDoctor_name={res.SurgeryDoctor_name}
                                    Surgery_date={res.Surgery_date}
                                    SurgeryNotes={res.SurgeryNotes}
                                    SurgeryTitle={res.SurgeryTitle}
                                    SurgeryNext={res.SurgeryNext}
                                    SurgeryReminderNotes={
                                      res.SurgeryReminderNotes
                                    }
                                  ></Surgerycard>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {medicaldetails && this.state.selected === false ? (
                        <div>
                          {medicaldetails.Surgery.map((res) => {
                            return (
                              <Surgerycard
                                tag_number={this.props.match.params.tag_number}
                                Surgery_ReportsLocations={
                                  res.Surgery_ReportsLocations
                                }
                                Surgery_ReportsNames={res.Surgery_ReportsNames}
                                Surgery_Reportlocation={
                                  res.Surgery_Reportlocation
                                }
                                Surgery_Reportname={res.Surgery_Reportname}
                                surgery_id={res.surgery_id}
                                Surgery_name={res.Surgery_name}
                                SurgeryDoctor_name={res.SurgeryDoctor_name}
                                Surgery_date={res.Surgery_date}
                                SurgeryNotes={res.SurgeryNotes}
                                SurgeryTitle={res.SurgeryTitle}
                                SurgeryNext={res.SurgeryNext}
                                SurgeryReminderNotes={res.SurgeryReminderNotes}
                              ></Surgerycard>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {filteroption === "Allergy" ? (
                    <div>
                      {this.state.selected === true &&
                      this.state.sortType === "name" ? (
                        <div>
                          {this.state.sortbyAD === true ? (
                            <div>
                              {AllergyList.map((res) => {
                                return (
                                  <Allergycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Allergy_ReportsLocations={
                                      res.Allergy_ReportsLocations
                                    }
                                    Allergy_ReportsNames={
                                      res.Allergy_ReportsNames
                                    }
                                    Allergy_Reportlocation={
                                      res.Allergy_Reportlocation
                                    }
                                    Allergy_Reportname={res.Allergy_Reportname}
                                    allergy_id={res.allergy_id}
                                    Allergy_name={res.Allergy_name}
                                    AllergyDoctor_name={res.AllergyDoctor_name}
                                    AllergyDiagnosis_date={
                                      res.AllergyDiagnosis_date
                                    }
                                    AllergyNotes={res.AllergyNotes}
                                    AllergyTitle={res.AllergyTitle}
                                    AllergyNext={res.AllergyNext}
                                    AllergyReminderNotes={
                                      res.AllergyReminderNotes
                                    }
                                  ></Allergycard>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {AllergyList.reverse().map((res) => {
                                return (
                                  <Allergycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Allergy_ReportsLocations={
                                      res.Allergy_ReportsLocations
                                    }
                                    Allergy_ReportsNames={
                                      res.Allergy_ReportsNames
                                    }
                                    Allergy_Reportlocation={
                                      res.Allergy_Reportlocation
                                    }
                                    Allergy_Reportname={res.Allergy_Reportname}
                                    allergy_id={res.allergy_id}
                                    Allergy_name={res.Allergy_name}
                                    AllergyDoctor_name={res.AllergyDoctor_name}
                                    AllergyDiagnosis_date={
                                      res.AllergyDiagnosis_date
                                    }
                                    AllergyNotes={res.AllergyNotes}
                                    AllergyTitle={res.AllergyTitle}
                                    AllergyNext={res.AllergyNext}
                                    AllergyReminderNotes={
                                      res.AllergyReminderNotes
                                    }
                                  ></Allergycard>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.selected === true &&
                      this.state.sortType === "appointment" ? (
                        <div>
                          {this.state.sortbyAD === true ? (
                            <div>
                              {AllergyDateList.map((res) => {
                                return (
                                  <Allergycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Allergy_ReportsLocations={
                                      res.Allergy_ReportsLocations
                                    }
                                    Allergy_ReportsNames={
                                      res.Allergy_ReportsNames
                                    }
                                    Allergy_Reportlocation={
                                      res.Allergy_Reportlocation
                                    }
                                    Allergy_Reportname={res.Allergy_Reportname}
                                    allergy_id={res.allergy_id}
                                    Allergy_name={res.Allergy_name}
                                    AllergyDoctor_name={res.AllergyDoctor_name}
                                    AllergyDiagnosis_date={
                                      res.AllergyDiagnosis_date
                                    }
                                    AllergyNotes={res.AllergyNotes}
                                    AllergyTitle={res.AllergyTitle}
                                    AllergyNext={res.AllergyNext}
                                    AllergyReminderNotes={
                                      res.AllergyReminderNotes
                                    }
                                  ></Allergycard>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {AllergyDateList.reverse().map((res) => {
                                return (
                                  <Allergycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Allergy_ReportsLocations={
                                      res.Allergy_ReportsLocations
                                    }
                                    Allergy_ReportsNames={
                                      res.Allergy_ReportsNames
                                    }
                                    Allergy_Reportlocation={
                                      res.Allergy_Reportlocation
                                    }
                                    Allergy_Reportname={res.Allergy_Reportname}
                                    allergy_id={res.allergy_id}
                                    Allergy_name={res.Allergy_name}
                                    AllergyDoctor_name={res.AllergyDoctor_name}
                                    AllergyDiagnosis_date={
                                      res.AllergyDiagnosis_date
                                    }
                                    AllergyNotes={res.AllergyNotes}
                                    AllergyTitle={res.AllergyTitle}
                                    AllergyNext={res.AllergyNext}
                                    AllergyReminderNotes={
                                      res.AllergyReminderNotes
                                    }
                                  ></Allergycard>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {medicaldetails && this.state.selected === false ? (
                        <div>
                          {medicaldetails.Allergy.map((res) => {
                            return (
                              <Allergycard
                                tag_number={this.props.match.params.tag_number}
                                Allergy_ReportsLocations={
                                  res.Allergy_ReportsLocations
                                }
                                Allergy_ReportsNames={res.Allergy_ReportsNames}
                                Allergy_Reportlocation={
                                  res.Allergy_Reportlocation
                                }
                                Allergy_Reportname={res.Allergy_Reportname}
                                allergy_id={res.allergy_id}
                                Allergy_name={res.Allergy_name}
                                AllergyDoctor_name={res.AllergyDoctor_name}
                                AllergyDiagnosis_date={
                                  res.AllergyDiagnosis_date
                                }
                                AllergyNotes={res.AllergyNotes}
                                AllergyTitle={res.AllergyTitle}
                                AllergyNext={res.AllergyNext}
                                AllergyReminderNotes={res.AllergyReminderNotes}
                              ></Allergycard>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {filteroption === "Medication" ? (
                    <div>
                      {this.state.selected === true &&
                      this.state.sortType === "name" ? (
                        <div>
                          {this.state.sortbyAD === true ? (
                            <div>
                              {MedicationList.map((res) => {
                                return (
                                  <Medicationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Medication_ReportsLocations={
                                      res.Medication_ReportsLocations
                                    }
                                    Medication_ReportsNames={
                                      res.Medication_ReportsNames
                                    }
                                    Medication_Reportlocation={
                                      res.Medication_Reportlocation
                                    }
                                    Medication_Reportname={
                                      res.Medication_Reportname
                                    }
                                    medication_id={res.medication_id}
                                    Medication_name={res.Medication_name}
                                    MedicationDoctor_name={
                                      res.MedicationDoctor_name
                                    }
                                    Medication_startdate={
                                      res.Medication_startdate
                                    }
                                    MedicationNotes={res.MedicationNotes}
                                    MedicationTitle={res.MedicationTitle}
                                    MedicationNext={res.MedicationNext}
                                    MedicationReminderNotes={
                                      res.MedicationReminderNotes
                                    }
                                  ></Medicationcard>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {MedicationList.reverse().map((res) => {
                                return (
                                  <Medicationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Medication_ReportsLocations={
                                      res.Medication_ReportsLocations
                                    }
                                    Medication_ReportsNames={
                                      res.Medication_ReportsNames
                                    }
                                    Medication_Reportlocation={
                                      res.Medication_Reportlocation
                                    }
                                    Medication_Reportname={
                                      res.Medication_Reportname
                                    }
                                    medication_id={res.medication_id}
                                    Medication_name={res.Medication_name}
                                    MedicationDoctor_name={
                                      res.MedicationDoctor_name
                                    }
                                    Medication_startdate={
                                      res.Medication_startdate
                                    }
                                    MedicationNotes={res.MedicationNotes}
                                    MedicationTitle={res.MedicationTitle}
                                    MedicationNext={res.MedicationNext}
                                    MedicationReminderNotes={
                                      res.MedicationReminderNotes
                                    }
                                  ></Medicationcard>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.selected === true &&
                      this.state.sortType === "appointment" ? (
                        <div>
                          {
                            //    MedicationDateList.map((res)=>{
                            //     return(<Medicationcard tag_number={this.props.match.params.tag_number} Medication_Reportlocation={res.Medication_Reportlocation} Medication_Reportname={res.Medication_Reportname} medication_id={res.medication_id} Medication_name={res.Medication_name} MedicationDoctor_name={res.MedicationDoctor_name} Medication_startdate={res.Medication_startdate} MedicationNotes={res.MedicationNotes} MedicationTitle={res.MedicationTitle} MedicationNext={res.MedicationNext}></Medicationcard>)

                            //    })

                            this.state.sortbyAD === true ? (
                              <div>
                                {MedicationDateList.map((res) => {
                                  return (
                                    <Medicationcard
                                      tag_number={
                                        this.props.match.params.tag_number
                                      }
                                      Medication_ReportsLocations={
                                        res.Medication_ReportsLocations
                                      }
                                      Medication_ReportsNames={
                                        res.Medication_ReportsNames
                                      }
                                      Medication_Reportlocation={
                                        res.Medication_Reportlocation
                                      }
                                      Medication_Reportname={
                                        res.Medication_Reportname
                                      }
                                      medication_id={res.medication_id}
                                      Medication_name={res.Medication_name}
                                      MedicationDoctor_name={
                                        res.MedicationDoctor_name
                                      }
                                      Medication_startdate={
                                        res.Medication_startdate
                                      }
                                      MedicationNotes={res.MedicationNotes}
                                      MedicationTitle={res.MedicationTitle}
                                      MedicationNext={res.MedicationNext}
                                      MedicationReminderNotes={
                                        res.MedicationReminderNotes
                                      }
                                    ></Medicationcard>
                                  );
                                })}
                              </div>
                            ) : (
                              <div>
                                {MedicationDateList.reverse().map((res) => {
                                  return (
                                    <Medicationcard
                                      tag_number={
                                        this.props.match.params.tag_number
                                      }
                                      Medication_ReportsLocations={
                                        res.Medication_ReportsLocations
                                      }
                                      Medication_ReportsNames={
                                        res.Medication_ReportsNames
                                      }
                                      Medication_Reportlocation={
                                        res.Medication_Reportlocation
                                      }
                                      Medication_Reportname={
                                        res.Medication_Reportname
                                      }
                                      medication_id={res.medication_id}
                                      Medication_name={res.Medication_name}
                                      MedicationDoctor_name={
                                        res.MedicationDoctor_name
                                      }
                                      Medication_startdate={
                                        res.Medication_startdate
                                      }
                                      MedicationNotes={res.MedicationNotes}
                                      MedicationTitle={res.MedicationTitle}
                                      MedicationNext={res.MedicationNext}
                                      MedicationReminderNotes={
                                        res.MedicationReminderNotes
                                      }
                                    ></Medicationcard>
                                  );
                                })}
                              </div>
                            )
                          }
                        </div>
                      ) : (
                        ""
                      )}
                      {medicaldetails && this.state.selected === false ? (
                        <div>
                          {medicaldetails.Medication.map((res) => {
                            return (
                              <Medicationcard
                                tag_number={this.props.match.params.tag_number}
                                Medication_ReportsLocations={
                                  res.Medication_ReportsLocations
                                }
                                Medication_ReportsNames={
                                  res.Medication_ReportsNames
                                }
                                Medication_Reportlocation={
                                  res.Medication_Reportlocation
                                }
                                Medication_Reportname={
                                  res.Medication_Reportname
                                }
                                medication_id={res.medication_id}
                                Medication_name={res.Medication_name}
                                MedicationDoctor_name={
                                  res.MedicationDoctor_name
                                }
                                Medication_startdate={res.Medication_startdate}
                                MedicationNotes={res.MedicationNotes}
                                MedicationTitle={res.MedicationTitle}
                                MedicationNext={res.MedicationNext}
                                MedicationReminderNotes={
                                  res.MedicationReminderNotes
                                }
                              ></Medicationcard>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {filteroption === "Medicalcondition" ? (
                    <div>
                      {this.state.selected === true &&
                      this.state.sortType === "name" ? (
                        <div>
                          {
                            //    MedicalConList.map((res)=>{
                            //     return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)
                            //    })

                            this.state.sortbyAD === true ? (
                              <div>
                                {MedicalConList.map((res) => {
                                  return (
                                    <Medicalconditioncard
                                      tag_number={
                                        this.props.match.params.tag_number
                                      }
                                      Medicalcondition_ReportsLocations={
                                        res.Medicalcondition_ReportsLocations
                                      }
                                      Medicalcondition_ReportsNames={
                                        res.Medicalcondition_ReportsNames
                                      }
                                      Medicalcondition_Reportlocation={
                                        res.Medicalcondition_Reportlocation
                                      }
                                      Medicalcondition_Reportname={
                                        res.Medicalcondition_Reportname
                                      }
                                      medicalcondition_id={
                                        res.medicalcondition_id
                                      }
                                      Medicalcondition_type={
                                        res.Medicalcondition_type
                                      }
                                      MedicalconditionDoctor_name={
                                        res.MedicalconditionDoctor_name
                                      }
                                      MedicalconditionDiagnosed_date={
                                        res.MedicalconditionDiagnosed_date
                                      }
                                      MedicalconditionNotes={
                                        res.MedicalconditionNotes
                                      }
                                      MedicalconditionTitle={
                                        res.MedicalconditionTitle
                                      }
                                      MedicalconditionNext={
                                        res.MedicalconditionNext
                                      }
                                      MedicalconditionReminderNotes={
                                        res.MedicalconditionReminderNotes
                                      }
                                    ></Medicalconditioncard>
                                  );
                                })}
                              </div>
                            ) : (
                              <div>
                                {MedicalConList.reverse().map((res) => {
                                  return (
                                    <Medicalconditioncard
                                      tag_number={
                                        this.props.match.params.tag_number
                                      }
                                      Medicalcondition_ReportsLocations={
                                        res.Medicalcondition_ReportsLocations
                                      }
                                      Medicalcondition_ReportsNames={
                                        res.Medicalcondition_ReportsNames
                                      }
                                      Medicalcondition_Reportlocation={
                                        res.Medicalcondition_Reportlocation
                                      }
                                      Medicalcondition_Reportname={
                                        res.Medicalcondition_Reportname
                                      }
                                      medicalcondition_id={
                                        res.medicalcondition_id
                                      }
                                      Medicalcondition_type={
                                        res.Medicalcondition_type
                                      }
                                      MedicalconditionDoctor_name={
                                        res.MedicalconditionDoctor_name
                                      }
                                      MedicalconditionDiagnosed_date={
                                        res.MedicalconditionDiagnosed_date
                                      }
                                      MedicalconditionNotes={
                                        res.MedicalconditionNotes
                                      }
                                      MedicalconditionTitle={
                                        res.MedicalconditionTitle
                                      }
                                      MedicalconditionNext={
                                        res.MedicalconditionNext
                                      }
                                      MedicalconditionReminderNotes={
                                        res.MedicalconditionReminderNotes
                                      }
                                    ></Medicalconditioncard>
                                  );
                                })}
                              </div>
                            )
                          }
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.selected === true &&
                      this.state.sortType === "appointment" ? (
                        <div>
                          {
                            //    MedicalConDateList.map((res)=>{
                            //     return(<Medicalconditioncard tag_number={this.props.match.params.tag_number} Medicalcondition_Reportlocation={res.Medicalcondition_Reportlocation} Medicalcondition_Reportname={res.Medicalcondition_Reportname} medicalcondition_id={res.medicalcondition_id} Medicalcondition_type={res.Medicalcondition_type} MedicalconditionDoctor_name={res.MedicalconditionDoctor_name} MedicalconditionDiagnosed_date={res.MedicalconditionDiagnosed_date} MedicalconditionNotes={res.MedicalconditionNotes} MedicalconditionTitle={res.MedicalconditionTitle} MedicalconditionNext={res.MedicalconditionNext}></Medicalconditioncard>)

                            //    })

                            this.state.sortbyAD === true ? (
                              <div>
                                {MedicalConDateList.map((res) => {
                                  return (
                                    <Medicalconditioncard
                                      tag_number={
                                        this.props.match.params.tag_number
                                      }
                                      Medicalcondition_ReportsLocations={
                                        res.Medicalcondition_ReportsLocations
                                      }
                                      Medicalcondition_ReportsNames={
                                        res.Medicalcondition_ReportsNames
                                      }
                                      Medicalcondition_Reportlocation={
                                        res.Medicalcondition_Reportlocation
                                      }
                                      Medicalcondition_Reportname={
                                        res.Medicalcondition_Reportname
                                      }
                                      medicalcondition_id={
                                        res.medicalcondition_id
                                      }
                                      Medicalcondition_type={
                                        res.Medicalcondition_type
                                      }
                                      MedicalconditionDoctor_name={
                                        res.MedicalconditionDoctor_name
                                      }
                                      MedicalconditionDiagnosed_date={
                                        res.MedicalconditionDiagnosed_date
                                      }
                                      MedicalconditionNotes={
                                        res.MedicalconditionNotes
                                      }
                                      MedicalconditionTitle={
                                        res.MedicalconditionTitle
                                      }
                                      MedicalconditionNext={
                                        res.MedicalconditionNext
                                      }
                                      MedicalconditionReminderNotes={
                                        res.MedicalconditionReminderNotes
                                      }
                                    ></Medicalconditioncard>
                                  );
                                })}
                              </div>
                            ) : (
                              <div>
                                {MedicalConDateList.reverse().map((res) => {
                                  return (
                                    <Medicalconditioncard
                                      tag_number={
                                        this.props.match.params.tag_number
                                      }
                                      Medicalcondition_ReportsLocations={
                                        res.Medicalcondition_ReportsLocations
                                      }
                                      Medicalcondition_ReportsNames={
                                        res.Medicalcondition_ReportsNames
                                      }
                                      Medicalcondition_Reportlocation={
                                        res.Medicalcondition_Reportlocation
                                      }
                                      Medicalcondition_Reportname={
                                        res.Medicalcondition_Reportname
                                      }
                                      medicalcondition_id={
                                        res.medicalcondition_id
                                      }
                                      Medicalcondition_type={
                                        res.Medicalcondition_type
                                      }
                                      MedicalconditionDoctor_name={
                                        res.MedicalconditionDoctor_name
                                      }
                                      MedicalconditionDiagnosed_date={
                                        res.MedicalconditionDiagnosed_date
                                      }
                                      MedicalconditionNotes={
                                        res.MedicalconditionNotes
                                      }
                                      MedicalconditionTitle={
                                        res.MedicalconditionTitle
                                      }
                                      MedicalconditionNext={
                                        res.MedicalconditionNext
                                      }
                                      MedicalconditionReminderNotes={
                                        res.MedicalconditionReminderNotes
                                      }
                                    ></Medicalconditioncard>
                                  );
                                })}
                              </div>
                            )
                          }
                        </div>
                      ) : (
                        ""
                      )}
                      {medicaldetails && this.state.selected === false ? (
                        <div>
                          {medicaldetails.Medicalcondition.map((res) => {
                            return (
                              <Medicalconditioncard
                                tag_number={this.props.match.params.tag_number}
                                Medicalcondition_ReportsLocations={
                                  res.Medicalcondition_ReportsLocations
                                }
                                Medicalcondition_ReportsNames={
                                  res.Medicalcondition_ReportsNames
                                }
                                Medicalcondition_Reportlocation={
                                  res.Medicalcondition_Reportlocation
                                }
                                Medicalcondition_Reportname={
                                  res.Medicalcondition_Reportname
                                }
                                medicalcondition_id={res.medicalcondition_id}
                                Medicalcondition_type={
                                  res.Medicalcondition_type
                                }
                                MedicalconditionDoctor_name={
                                  res.MedicalconditionDoctor_name
                                }
                                MedicalconditionDiagnosed_date={
                                  res.MedicalconditionDiagnosed_date
                                }
                                MedicalconditionNotes={
                                  res.MedicalconditionNotes
                                }
                                MedicalconditionTitle={
                                  res.MedicalconditionTitle
                                }
                                MedicalconditionNext={res.MedicalconditionNext}
                                MedicalconditionReminderNotes={
                                  res.MedicalconditionReminderNotes
                                }
                              ></Medicalconditioncard>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {filteroption === "Measurement" ? (
                    <div>
                      {this.state.selected === true &&
                      this.state.sortType === "name" ? (
                        <div>
                          {this.state.sortbyAD === true ? (
                            <div>
                              {MeasureList.map((res) => {
                                return (
                                  <Measurementcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Measurement_ReportsLocations={
                                      res.Measurement_ReportsLocations
                                    }
                                    Measurement_ReportsNames={
                                      res.Measurement_ReportsNames
                                    }
                                    Measurement_Reportlocation={
                                      res.Measurement_Reportlocation
                                    }
                                    Measurement_Reportname={
                                      res.Measurement_Reportname
                                    }
                                    measurement_id={res.measurement_id}
                                    Weight={res.Weight}
                                    Measuredby={res.Measuredby}
                                    Measurement_date={res.Measurement_date}
                                    MeasurementNotes={res.MeasurementNotes}
                                    MeasurementTitle={res.MeasurementTitle}
                                    MeasurementNext={res.MeasurementNext}
                                    MeasurementReminderNotes={
                                      res.MeasurementReminderNotes
                                    }
                                  ></Measurementcard>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {MeasureList.reverse().map((res) => {
                                return (
                                  <Measurementcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Measurement_ReportsLocations={
                                      res.Measurement_ReportsLocations
                                    }
                                    Measurement_ReportsNames={
                                      res.Measurement_ReportsNames
                                    }
                                    Measurement_Reportlocation={
                                      res.Measurement_Reportlocation
                                    }
                                    Measurement_Reportname={
                                      res.Measurement_Reportname
                                    }
                                    measurement_id={res.measurement_id}
                                    Weight={res.Weight}
                                    Measuredby={res.Measuredby}
                                    Measurement_date={res.Measurement_date}
                                    MeasurementNotes={res.MeasurementNotes}
                                    MeasurementTitle={res.MeasurementTitle}
                                    MeasurementNext={res.MeasurementNext}
                                    MeasurementReminderNotes={
                                      res.MeasurementReminderNotes
                                    }
                                  ></Measurementcard>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.selected === true &&
                      this.state.sortType === "appointment" ? (
                        <div>
                          {this.state.sortbyAD === true ? (
                            <div>
                              {MeasureDateList.map((res) => {
                                return (
                                  <Measurementcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Measurement_ReportsLocations={
                                      res.Measurement_ReportsLocations
                                    }
                                    Measurement_ReportsNames={
                                      res.Measurement_ReportsNames
                                    }
                                    Measurement_Reportlocation={
                                      res.Measurement_Reportlocation
                                    }
                                    Measurement_Reportname={
                                      res.Measurement_Reportname
                                    }
                                    measurement_id={res.measurement_id}
                                    Weight={res.Weight}
                                    Measuredby={res.Measuredby}
                                    Measurement_date={res.Measurement_date}
                                    MeasurementNotes={res.MeasurementNotes}
                                    MeasurementTitle={res.MeasurementTitle}
                                    MeasurementNext={res.MeasurementNext}
                                    MeasurementReminderNotes={
                                      res.MeasurementReminderNotes
                                    }
                                  ></Measurementcard>
                                );
                              })}
                            </div>
                          ) : (
                            <div>
                              {MeasureDateList.reverse().map((res) => {
                                return (
                                  <Measurementcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Measurement_ReportsLocations={
                                      res.Measurement_ReportsLocations
                                    }
                                    Measurement_ReportsNames={
                                      res.Measurement_ReportsNames
                                    }
                                    Measurement_Reportlocation={
                                      res.Measurement_Reportlocation
                                    }
                                    Measurement_Reportname={
                                      res.Measurement_Reportname
                                    }
                                    measurement_id={res.measurement_id}
                                    Weight={res.Weight}
                                    Measuredby={res.Measuredby}
                                    Measurement_date={res.Measurement_date}
                                    MeasurementNotes={res.MeasurementNotes}
                                    MeasurementTitle={res.MeasurementTitle}
                                    MeasurementNext={res.MeasurementNext}
                                    MeasurementReminderNotes={
                                      res.MeasurementReminderNotes
                                    }
                                  ></Measurementcard>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {medicaldetails && this.state.selected === false ? (
                        <div>
                          {medicaldetails.Measurement.map((res) => {
                            return (
                              <Measurementcard
                                tag_number={this.props.match.params.tag_number}
                                Measurement_ReportsLocations={
                                  res.Measurement_ReportsLocations
                                }
                                Measurement_ReportsNames={
                                  res.Measurement_ReportsNames
                                }
                                Measurement_Reportlocation={
                                  res.Measurement_Reportlocation
                                }
                                Measurement_Reportname={
                                  res.Measurement_Reportname
                                }
                                measurement_id={res.measurement_id}
                                Weight={res.Weight}
                                Measuredby={res.Measuredby}
                                Measurement_date={res.Measurement_date}
                                MeasurementNotes={res.MeasurementNotes}
                                MeasurementTitle={res.MeasurementTitle}
                                MeasurementNext={res.MeasurementNext}
                                MeasurementReminderNotes={
                                  res.MeasurementReminderNotes
                                }
                              ></Measurementcard>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {filteroption === "filter" ? (
                    <div>
                      {this.state.selected === true &&
                      this.state.sortType === "name" ? (
                        <div>
                          {this.state.sortbyAD === true ? (
                            <div>
                              {AllergyList ? (
                                <div>
                                  {AllergyList.map((res) => {
                                    return (
                                      <Allergycard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Allergy_ReportsLocations={
                                          res.Allergy_ReportsLocations
                                        }
                                        Allergy_ReportsNames={
                                          res.Allergy_ReportsNames
                                        }
                                        Allergy_Reportlocation={
                                          res.Allergy_Reportlocation
                                        }
                                        Allergy_Reportname={
                                          res.Allergy_Reportname
                                        }
                                        allergy_id={res.allergy_id}
                                        Allergy_name={res.Allergy_name}
                                        AllergyDoctor_name={
                                          res.AllergyDoctor_name
                                        }
                                        AllergyDiagnosis_date={
                                          res.AllergyDiagnosis_date
                                        }
                                        AllergyNotes={res.AllergyNotes}
                                        AllergyTitle={res.AllergyTitle}
                                        AllergyNext={res.AllergyNext}
                                        AllergyReminderNotes={
                                          res.AllergyReminderNotes
                                        }
                                      ></Allergycard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {MedicationList ? (
                                <div>
                                  {MedicationList.map((res) => {
                                    return (
                                      <Medicationcard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Medication_ReportsLocations={
                                          res.Medication_ReportsLocations
                                        }
                                        Medication_ReportsNames={
                                          res.Medication_ReportsNames
                                        }
                                        Medication_Reportlocation={
                                          res.Medication_Reportlocation
                                        }
                                        Medication_Reportname={
                                          res.Medication_Reportname
                                        }
                                        medication_id={res.medication_id}
                                        Medication_name={res.Medication_name}
                                        MedicationDoctor_name={
                                          res.MedicationDoctor_name
                                        }
                                        Medication_startdate={
                                          res.Medication_startdate
                                        }
                                        MedicationNotes={res.MedicationNotes}
                                        MedicationTitle={res.MedicationTitle}
                                        MedicationNext={res.MedicationNext}
                                        MedicationReminderNotes={
                                          res.MedicationReminderNotes
                                        }
                                      ></Medicationcard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {MedicalConList ? (
                                <div>
                                  {MedicalConList.map((res) => {
                                    return (
                                      <Medicalconditioncard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Medicalcondition_ReportsLocations={
                                          res.Medicalcondition_ReportsLocations
                                        }
                                        Medicalcondition_ReportsNames={
                                          res.Medicalcondition_ReportsNames
                                        }
                                        Medicalcondition_Reportlocation={
                                          res.Medicalcondition_Reportlocation
                                        }
                                        Medicalcondition_Reportname={
                                          res.Medicalcondition_Reportname
                                        }
                                        medicalcondition_id={
                                          res.medicalcondition_id
                                        }
                                        Medicalcondition_type={
                                          res.Medicalcondition_type
                                        }
                                        MedicalconditionDoctor_name={
                                          res.MedicalconditionDoctor_name
                                        }
                                        MedicalconditionDiagnosed_date={
                                          res.MedicalconditionDiagnosed_date
                                        }
                                        MedicalconditionNotes={
                                          res.MedicalconditionNotes
                                        }
                                        MedicalconditionTitle={
                                          res.MedicalconditionTitle
                                        }
                                        MedicalconditionNext={
                                          res.MedicalconditionNext
                                        }
                                        MedicalconditionReminderNotes={
                                          res.MedicalconditionReminderNotes
                                        }
                                      ></Medicalconditioncard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {MeasureList ? (
                                <div>
                                  {MeasureList.map((res) => {
                                    return (
                                      <Measurementcard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Measurement_ReportsLocations={
                                          res.Measurement_ReportsLocations
                                        }
                                        Measurement_ReportsNames={
                                          res.Measurement_ReportsNames
                                        }
                                        Measurement_Reportlocation={
                                          res.Measurement_Reportlocation
                                        }
                                        Measurement_Reportname={
                                          res.Measurement_Reportname
                                        }
                                        measurement_id={res.measurement_id}
                                        Weight={res.Weight}
                                        Measuredby={res.Measuredby}
                                        Measurement_date={res.Measurement_date}
                                        MeasurementNotes={res.MeasurementNotes}
                                        MeasurementTitle={res.MeasurementTitle}
                                        MeasurementNext={res.MeasurementNext}
                                        MeasurementReminderNotes={
                                          res.MeasurementReminderNotes
                                        }
                                      ></Measurementcard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {SurgeryList ? (
                                <div>
                                  {SurgeryList.map((res) => {
                                    return (
                                      <Surgerycard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Surgery_ReportsLocations={
                                          res.Surgery_ReportsLocations
                                        }
                                        Surgery_ReportsNames={
                                          res.Surgery_ReportsNames
                                        }
                                        Surgery_Reportlocation={
                                          res.Surgery_Reportlocation
                                        }
                                        Surgery_Reportname={
                                          res.Surgery_Reportname
                                        }
                                        surgery_id={res.surgery_id}
                                        Surgery_name={res.Surgery_name}
                                        SurgeryDoctor_name={
                                          res.SurgeryDoctor_name
                                        }
                                        Surgery_date={res.Surgery_date}
                                        SurgeryNotes={res.SurgeryNotes}
                                        SurgeryTitle={res.SurgeryTitle}
                                        SurgeryNext={res.SurgeryNext}
                                        SurgeryReminderNotes={
                                          res.SurgeryReminderNotes
                                        }
                                      ></Surgerycard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {VaccineList ? (
                                <div>
                                  {VaccineList.map((res) => {
                                    return (
                                      <Vaccinationcard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Vaccination_Reportlocation={
                                          res.Vaccination_Reportlocation
                                        }
                                        Vaccination_Reportname={
                                          res.Vaccination_Reportname
                                        }
                                        Vaccination_ReportsLocations={
                                          res.Vaccination_ReportsLocations
                                        }
                                        Vaccination_ReportsNames={
                                          res.Vaccination_ReportsNames
                                        }
                                        vaccination_id={res.vaccination_id}
                                        Vaccination_name={res.Vaccination_name}
                                        VaccinationDoctor_name={
                                          res.VaccinationDoctor_name
                                        }
                                        VaccinationAdministration_date={
                                          res.VaccinationAdministration_date
                                        }
                                        VaccinationNotes={res.VaccinationNotes}
                                        VaccinationTitle={res.VaccinationTitle}
                                        VaccinationNext={res.VaccinationNext}
                                        VaccinationReminderNotes={
                                          res.VaccinationReminderNotes
                                        }
                                      ></Vaccinationcard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div>
                              {/* ReverseOne */}
                              {AllergyList ? (
                                <div>
                                  {AllergyList.reverse().map((res) => {
                                    return (
                                      <Allergycard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Allergy_ReportsLocations={
                                          res.Allergy_ReportsLocations
                                        }
                                        Allergy_ReportsNames={
                                          res.Allergy_ReportsNames
                                        }
                                        Allergy_Reportlocation={
                                          res.Allergy_Reportlocation
                                        }
                                        Allergy_Reportname={
                                          res.Allergy_Reportname
                                        }
                                        allergy_id={res.allergy_id}
                                        Allergy_name={res.Allergy_name}
                                        AllergyDoctor_name={
                                          res.AllergyDoctor_name
                                        }
                                        AllergyDiagnosis_date={
                                          res.AllergyDiagnosis_date
                                        }
                                        AllergyNotes={res.AllergyNotes}
                                        AllergyTitle={res.AllergyTitle}
                                        AllergyNext={res.AllergyNext}
                                        AllergyReminderNotes={
                                          res.AllergyReminderNotes
                                        }
                                      ></Allergycard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {MedicationList ? (
                                <div>
                                  {MedicationList.reverse().map((res) => {
                                    return (
                                      <Medicationcard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Medication_ReportsLocations={
                                          res.Medication_ReportsLocations
                                        }
                                        Medication_ReportsNames={
                                          res.Medication_ReportsNames
                                        }
                                        Medication_Reportlocation={
                                          res.Medication_Reportlocation
                                        }
                                        Medication_Reportname={
                                          res.Medication_Reportname
                                        }
                                        medication_id={res.medication_id}
                                        Medication_name={res.Medication_name}
                                        MedicationDoctor_name={
                                          res.MedicationDoctor_name
                                        }
                                        Medication_startdate={
                                          res.Medication_startdate
                                        }
                                        MedicationNotes={res.MedicationNotes}
                                        MedicationTitle={res.MedicationTitle}
                                        MedicationNext={res.MedicationNext}
                                        MedicationReminderNotes={
                                          res.MedicationReminderNotes
                                        }
                                      ></Medicationcard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {MedicalConList ? (
                                <div>
                                  {MedicalConList.reverse().map((res) => {
                                    return (
                                      <Medicalconditioncard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Medicalcondition_ReportsLocations={
                                          res.Medicalcondition_ReportsLocations
                                        }
                                        Medicalcondition_ReportsNames={
                                          res.Medicalcondition_ReportsNames
                                        }
                                        Medicalcondition_Reportlocation={
                                          res.Medicalcondition_Reportlocation
                                        }
                                        Medicalcondition_Reportname={
                                          res.Medicalcondition_Reportname
                                        }
                                        medicalcondition_id={
                                          res.medicalcondition_id
                                        }
                                        Medicalcondition_type={
                                          res.Medicalcondition_type
                                        }
                                        MedicalconditionDoctor_name={
                                          res.MedicalconditionDoctor_name
                                        }
                                        MedicalconditionDiagnosed_date={
                                          res.MedicalconditionDiagnosed_date
                                        }
                                        MedicalconditionNotes={
                                          res.MedicalconditionNotes
                                        }
                                        MedicalconditionTitle={
                                          res.MedicalconditionTitle
                                        }
                                        MedicalconditionNext={
                                          res.MedicalconditionNext
                                        }
                                        MedicalconditionReminderNotes={
                                          res.MedicalconditionReminderNotes
                                        }
                                      ></Medicalconditioncard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {MeasureList ? (
                                <div>
                                  {MeasureList.reverse().map((res) => {
                                    return (
                                      <Measurementcard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Measurement_ReportsLocations={
                                          res.Measurement_ReportsLocations
                                        }
                                        Measurement_ReportsNames={
                                          res.Measurement_ReportsNames
                                        }
                                        Measurement_Reportlocation={
                                          res.Measurement_Reportlocation
                                        }
                                        Measurement_Reportname={
                                          res.Measurement_Reportname
                                        }
                                        measurement_id={res.measurement_id}
                                        Weight={res.Weight}
                                        Measuredby={res.Measuredby}
                                        Measurement_date={res.Measurement_date}
                                        MeasurementNotes={res.MeasurementNotes}
                                        MeasurementTitle={res.MeasurementTitle}
                                        MeasurementNext={res.MeasurementNext}
                                        MeasurementReminderNotes={
                                          res.MeasurementReminderNotes
                                        }
                                      ></Measurementcard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {SurgeryList ? (
                                <div>
                                  {SurgeryList.reverse().map((res) => {
                                    return (
                                      <Surgerycard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Surgery_ReportsLocations={
                                          res.Surgery_ReportsLocations
                                        }
                                        Surgery_ReportsNames={
                                          res.Surgery_ReportsNames
                                        }
                                        Surgery_Reportlocation={
                                          res.Surgery_Reportlocation
                                        }
                                        Surgery_Reportname={
                                          res.Surgery_Reportname
                                        }
                                        surgery_id={res.surgery_id}
                                        Surgery_name={res.Surgery_name}
                                        SurgeryDoctor_name={
                                          res.SurgeryDoctor_name
                                        }
                                        Surgery_date={res.Surgery_date}
                                        SurgeryNotes={res.SurgeryNotes}
                                        SurgeryTitle={res.SurgeryTitle}
                                        SurgeryNext={res.SurgeryNext}
                                        SurgeryReminderNotes={
                                          res.SurgeryReminderNotes
                                        }
                                      ></Surgerycard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                              {VaccineList ? (
                                <div>
                                  {VaccineList.reverse().map((res) => {
                                    return (
                                      <Vaccinationcard
                                        tag_number={
                                          this.props.match.params.tag_number
                                        }
                                        Vaccination_Reportlocation={
                                          res.Vaccination_Reportlocation
                                        }
                                        Vaccination_Reportname={
                                          res.Vaccination_Reportname
                                        }
                                        Vaccination_ReportsLocations={
                                          res.Vaccination_ReportsLocations
                                        }
                                        Vaccination_ReportsNames={
                                          res.Vaccination_ReportsNames
                                        }
                                        vaccination_id={res.vaccination_id}
                                        Vaccination_name={res.Vaccination_name}
                                        VaccinationDoctor_name={
                                          res.VaccinationDoctor_name
                                        }
                                        VaccinationAdministration_date={
                                          res.VaccinationAdministration_date
                                        }
                                        VaccinationNotes={res.VaccinationNotes}
                                        VaccinationTitle={res.VaccinationTitle}
                                        VaccinationNext={res.VaccinationNext}
                                        VaccinationReminderNotes={
                                          res.VaccinationReminderNotes
                                        }
                                      ></Vaccinationcard>
                                    );
                                  })}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

                      {medicaldetails && this.state.selected === false ? (
                        <div>
                          {medicaldetails ? (
                            <div>
                              {this.state.VaccineList.map((res) => {
                                return (
                                  <Vaccinationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Vaccination_Reportlocation={
                                      res.Vaccination_Reportlocation
                                    }
                                    Vaccination_Reportname={
                                      res.Vaccination_Reportname
                                    }
                                    Vaccination_ReportsLocations={
                                      res.Vaccination_ReportsLocations
                                    }
                                    Vaccination_ReportsNames={
                                      res.Vaccination_ReportsNames
                                    }
                                    vaccination_id={res.vaccination_id}
                                    Vaccination_name={res.Vaccination_name}
                                    VaccinationDoctor_name={
                                      res.VaccinationDoctor_name
                                    }
                                    VaccinationAdministration_date={
                                      res.VaccinationAdministration_date
                                    }
                                    VaccinationNotes={res.VaccinationNotes}
                                    VaccinationTitle={res.VaccinationTitle}
                                    VaccinationNext={res.VaccinationNext}
                                    VaccinationReminderNotes={
                                      res.VaccinationReminderNotes
                                    }
                                  ></Vaccinationcard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {medicaldetails ? (
                            <div>
                              {this.state.SurgeryList.map((res) => {
                                return (
                                  <Surgerycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Surgery_ReportsLocations={
                                      res.Surgery_ReportsLocations
                                    }
                                    Surgery_ReportsNames={
                                      res.Surgery_ReportsNames
                                    }
                                    Surgery_Reportlocation={
                                      res.Surgery_Reportlocation
                                    }
                                    Surgery_Reportname={res.Surgery_Reportname}
                                    surgery_id={res.surgery_id}
                                    Surgery_name={res.Surgery_name}
                                    SurgeryDoctor_name={res.SurgeryDoctor_name}
                                    Surgery_date={res.Surgery_date}
                                    SurgeryNotes={res.SurgeryNotes}
                                    SurgeryTitle={res.SurgeryTitle}
                                    SurgeryNext={res.SurgeryNext}
                                    SurgeryReminderNotes={
                                      res.SurgeryReminderNotes
                                    }
                                  ></Surgerycard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {medicaldetails ? (
                            <div>
                              {this.state.AllergyList.map((res) => {
                                return (
                                  <Allergycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Allergy_ReportsLocations={
                                      res.Allergy_ReportsLocations
                                    }
                                    Allergy_ReportsNames={
                                      res.Allergy_ReportsNames
                                    }
                                    Allergy_Reportlocation={
                                      res.Allergy_Reportlocation
                                    }
                                    Allergy_Reportname={res.Allergy_Reportname}
                                    allergy_id={res.allergy_id}
                                    Allergy_name={res.Allergy_name}
                                    AllergyDoctor_name={res.AllergyDoctor_name}
                                    AllergyDiagnosis_date={
                                      res.AllergyDiagnosis_date
                                    }
                                    AllergyNotes={res.AllergyNotes}
                                    AllergyTitle={res.AllergyTitle}
                                    AllergyNext={res.AllergyNext}
                                    AllergyReminderNotes={
                                      res.AllergyReminderNotes
                                    }
                                  ></Allergycard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {medicaldetails ? (
                            <div>
                              {this.state.MedicationList.map((res) => {
                                return (
                                  <Medicationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Medication_ReportsLocations={
                                      res.Medication_ReportsLocations
                                    }
                                    Medication_ReportsNames={
                                      res.Medication_ReportsNames
                                    }
                                    Medication_Reportlocation={
                                      res.Medication_Reportlocation
                                    }
                                    Medication_Reportname={
                                      res.Medication_Reportname
                                    }
                                    medication_id={res.medication_id}
                                    Medication_name={res.Medication_name}
                                    MedicationDoctor_name={
                                      res.MedicationDoctor_name
                                    }
                                    Medication_startdate={
                                      res.Medication_startdate
                                    }
                                    MedicationNotes={res.MedicationNotes}
                                    MedicationTitle={res.MedicationTitle}
                                    MedicationNext={res.MedicationNext}
                                    MedicationReminderNotes={
                                      res.MedicationReminderNotes
                                    }
                                  ></Medicationcard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {medicaldetails ? (
                            <div>
                              {this.state.MedicalConList.map((res) => {
                                return (
                                  <Medicalconditioncard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Medicalcondition_ReportsLocations={
                                      res.Medicalcondition_ReportsLocations
                                    }
                                    Medicalcondition_ReportsNames={
                                      res.Medicalcondition_ReportsNames
                                    }
                                    Medicalcondition_Reportlocation={
                                      res.Medicalcondition_Reportlocation
                                    }
                                    Medicalcondition_Reportname={
                                      res.Medicalcondition_Reportname
                                    }
                                    medicalcondition_id={
                                      res.medicalcondition_id
                                    }
                                    Medicalcondition_type={
                                      res.Medicalcondition_type
                                    }
                                    MedicalconditionDoctor_name={
                                      res.MedicalconditionDoctor_name
                                    }
                                    MedicalconditionDiagnosed_date={
                                      res.MedicalconditionDiagnosed_date
                                    }
                                    MedicalconditionNotes={
                                      res.MedicalconditionNotes
                                    }
                                    MedicalconditionTitle={
                                      res.MedicalconditionTitle
                                    }
                                    MedicalconditionNext={
                                      res.MedicalconditionNext
                                    }
                                    MedicalconditionReminderNotes={
                                      res.MedicalconditionReminderNotes
                                    }
                                  ></Medicalconditioncard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {medicaldetails ? (
                            <div>
                              {this.state.MeasureList.map((res) => {
                                return (
                                  <Measurementcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Measurement_ReportsLocations={
                                      res.Measurement_ReportsLocations
                                    }
                                    Measurement_ReportsNames={
                                      res.Measurement_ReportsNames
                                    }
                                    Measurement_Reportlocation={
                                      res.Measurement_Reportlocation
                                    }
                                    Measurement_Reportname={
                                      res.Measurement_Reportname
                                    }
                                    measurement_id={res.measurement_id}
                                    Weight={res.Weight}
                                    Measuredby={res.Measuredby}
                                    Measurement_date={res.Measurement_date}
                                    MeasurementNotes={res.MeasurementNotes}
                                    MeasurementTitle={res.MeasurementTitle}
                                    MeasurementNext={res.MeasurementNext}
                                    MeasurementReminderNotes={
                                      res.MeasurementReminderNotes
                                    }
                                  ></Measurementcard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.selected === true &&
                  this.state.sortType === "appointment" &&
                  filteroption === "filter" ? (
                    <div>
                      {this.state.sortbyAD === true ? (
                        <div>
                          {VaccineDateList ? (
                            <div>
                              {VaccineDateList.map((res) => {
                                return (
                                  <Vaccinationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Vaccination_Reportlocation={
                                      res.Vaccination_Reportlocation
                                    }
                                    Vaccination_Reportname={
                                      res.Vaccination_Reportname
                                    }
                                    Vaccination_ReportsLocations={
                                      res.Vaccination_ReportsLocations
                                    }
                                    Vaccination_ReportsNames={
                                      res.Vaccination_ReportsNames
                                    }
                                    vaccination_id={res.vaccination_id}
                                    Vaccination_name={res.Vaccination_name}
                                    VaccinationDoctor_name={
                                      res.VaccinationDoctor_name
                                    }
                                    VaccinationAdministration_date={
                                      res.VaccinationAdministration_date
                                    }
                                    VaccinationNotes={res.VaccinationNotes}
                                    VaccinationTitle={res.VaccinationTitle}
                                    VaccinationNext={res.VaccinationNext}
                                    VaccinationReminderNotes={
                                      res.VaccinationReminderNotes
                                    }
                                  ></Vaccinationcard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {SurgeryDateList ? (
                            <div>
                              {SurgeryDateList.map((res) => {
                                return (
                                  <Surgerycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Surgery_ReportsLocations={
                                      res.Surgery_ReportsLocations
                                    }
                                    Surgery_ReportsNames={
                                      res.Surgery_ReportsNames
                                    }
                                    Surgery_Reportlocation={
                                      res.Surgery_Reportlocation
                                    }
                                    Surgery_Reportname={res.Surgery_Reportname}
                                    surgery_id={res.surgery_id}
                                    Surgery_name={res.Surgery_name}
                                    SurgeryDoctor_name={res.SurgeryDoctor_name}
                                    Surgery_date={res.Surgery_date}
                                    SurgeryNotes={res.SurgeryNotes}
                                    SurgeryTitle={res.SurgeryTitle}
                                    SurgeryNext={res.SurgeryNext}
                                    SurgeryReminderNotes={
                                      res.SurgeryReminderNotes
                                    }
                                  ></Surgerycard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {AllergyDateList ? (
                            <div>
                              {AllergyDateList.map((res) => {
                                return (
                                  <Allergycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Allergy_ReportsLocations={
                                      res.Allergy_ReportsLocations
                                    }
                                    Allergy_ReportsNames={
                                      res.Allergy_ReportsNames
                                    }
                                    Allergy_Reportlocation={
                                      res.Allergy_Reportlocation
                                    }
                                    Allergy_Reportname={res.Allergy_Reportname}
                                    allergy_id={res.allergy_id}
                                    Allergy_name={res.Allergy_name}
                                    AllergyDoctor_name={res.AllergyDoctor_name}
                                    AllergyDiagnosis_date={
                                      res.AllergyDiagnosis_date
                                    }
                                    AllergyNotes={res.AllergyNotes}
                                    AllergyTitle={res.AllergyTitle}
                                    AllergyNext={res.AllergyNext}
                                    AllergyReminderNotes={
                                      res.AllergyReminderNotes
                                    }
                                  ></Allergycard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {MedicationDateList ? (
                            <div>
                              {MedicationDateList.map((res) => {
                                return (
                                  <Medicationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Medication_ReportsLocations={
                                      res.Medication_ReportsLocations
                                    }
                                    Medication_ReportsNames={
                                      res.Medication_ReportsNames
                                    }
                                    Medication_Reportlocation={
                                      res.Medication_Reportlocation
                                    }
                                    Medication_Reportname={
                                      res.Medication_Reportname
                                    }
                                    medication_id={res.medication_id}
                                    Medication_name={res.Medication_name}
                                    MedicationDoctor_name={
                                      res.MedicationDoctor_name
                                    }
                                    Medication_startdate={
                                      res.Medication_startdate
                                    }
                                    MedicationNotes={res.MedicationNotes}
                                    MedicationTitle={res.MedicationTitle}
                                    MedicationNext={res.MedicationNext}
                                    MedicationReminderNotes={
                                      res.MedicationReminderNotes
                                    }
                                  ></Medicationcard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {MedicalConDateList ? (
                            <div>
                              {MedicalConDateList.map((res) => {
                                return (
                                  <Medicalconditioncard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Medicalcondition_ReportsLocations={
                                      res.Medicalcondition_ReportsLocations
                                    }
                                    Medicalcondition_ReportsNames={
                                      res.Medicalcondition_ReportsNames
                                    }
                                    Medicalcondition_Reportlocation={
                                      res.Medicalcondition_Reportlocation
                                    }
                                    Medicalcondition_Reportname={
                                      res.Medicalcondition_Reportname
                                    }
                                    medicalcondition_id={
                                      res.medicalcondition_id
                                    }
                                    Medicalcondition_type={
                                      res.Medicalcondition_type
                                    }
                                    MedicalconditionDoctor_name={
                                      res.MedicalconditionDoctor_name
                                    }
                                    MedicalconditionDiagnosed_date={
                                      res.MedicalconditionDiagnosed_date
                                    }
                                    MedicalconditionNotes={
                                      res.MedicalconditionNotes
                                    }
                                    MedicalconditionTitle={
                                      res.MedicalconditionTitle
                                    }
                                    MedicalconditionNext={
                                      res.MedicalconditionNext
                                    }
                                    MedicalconditionReminderNotes={
                                      res.MedicalconditionReminderNotes
                                    }
                                  ></Medicalconditioncard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {MeasureDateList ? (
                            <div>
                              {MeasureDateList.map((res) => {
                                return (
                                  <Measurementcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Measurement_ReportsLocations={
                                      res.Measurement_ReportsLocations
                                    }
                                    Measurement_ReportsNames={
                                      res.Measurement_ReportsNames
                                    }
                                    Measurement_Reportlocation={
                                      res.Measurement_Reportlocation
                                    }
                                    Measurement_Reportname={
                                      res.Measurement_Reportname
                                    }
                                    measurement_id={res.measurement_id}
                                    Weight={res.Weight}
                                    Measuredby={res.Measuredby}
                                    Measurement_date={res.Measurement_date}
                                    MeasurementNotes={res.MeasurementNotes}
                                    MeasurementTitle={res.MeasurementTitle}
                                    MeasurementNext={res.MeasurementNext}
                                    MeasurementReminderNotes={
                                      res.MeasurementReminderNotes
                                    }
                                  ></Measurementcard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div>
                          {/* ReverseOne */}

                          {VaccineDateList ? (
                            <div>
                              {VaccineDateList.reverse().map((res) => {
                                return (
                                  <Vaccinationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Vaccination_Reportlocation={
                                      res.Vaccination_Reportlocation
                                    }
                                    Vaccination_Reportname={
                                      res.Vaccination_Reportname
                                    }
                                    Vaccination_ReportsLocations={
                                      res.Vaccination_ReportsLocations
                                    }
                                    Vaccination_ReportsNames={
                                      res.Vaccination_ReportsNames
                                    }
                                    vaccination_id={res.vaccination_id}
                                    Vaccination_name={res.Vaccination_name}
                                    VaccinationDoctor_name={
                                      res.VaccinationDoctor_name
                                    }
                                    VaccinationAdministration_date={
                                      res.VaccinationAdministration_date
                                    }
                                    VaccinationNotes={res.VaccinationNotes}
                                    VaccinationTitle={res.VaccinationTitle}
                                    VaccinationNext={res.VaccinationNext}
                                    VaccinationReminderNotes={
                                      res.VaccinationReminderNotes
                                    }
                                  ></Vaccinationcard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {SurgeryDateList ? (
                            <div>
                              {SurgeryDateList.reverse().map((res) => {
                                return (
                                  <Surgerycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Surgery_ReportsLocations={
                                      res.Surgery_ReportsLocations
                                    }
                                    Surgery_ReportsNames={
                                      res.Surgery_ReportsNames
                                    }
                                    Surgery_Reportlocation={
                                      res.Surgery_Reportlocation
                                    }
                                    Surgery_Reportname={res.Surgery_Reportname}
                                    surgery_id={res.surgery_id}
                                    Surgery_name={res.Surgery_name}
                                    SurgeryDoctor_name={res.SurgeryDoctor_name}
                                    Surgery_date={res.Surgery_date}
                                    SurgeryNotes={res.SurgeryNotes}
                                    SurgeryTitle={res.SurgeryTitle}
                                    SurgeryNext={res.SurgeryNext}
                                    SurgeryReminderNotes={
                                      res.SurgeryReminderNotes
                                    }
                                  ></Surgerycard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {AllergyDateList ? (
                            <div>
                              {AllergyDateList.reverse().map((res) => {
                                return (
                                  <Allergycard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Allergy_ReportsLocations={
                                      res.Allergy_ReportsLocations
                                    }
                                    Allergy_ReportsNames={
                                      res.Allergy_ReportsNames
                                    }
                                    Allergy_Reportlocation={
                                      res.Allergy_Reportlocation
                                    }
                                    Allergy_Reportname={res.Allergy_Reportname}
                                    allergy_id={res.allergy_id}
                                    Allergy_name={res.Allergy_name}
                                    AllergyDoctor_name={res.AllergyDoctor_name}
                                    AllergyDiagnosis_date={
                                      res.AllergyDiagnosis_date
                                    }
                                    AllergyNotes={res.AllergyNotes}
                                    AllergyTitle={res.AllergyTitle}
                                    AllergyNext={res.AllergyNext}
                                    AllergyReminderNotes={
                                      res.AllergyReminderNotes
                                    }
                                  ></Allergycard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {MedicationDateList ? (
                            <div>
                              {MedicationDateList.reverse().map((res) => {
                                return (
                                  <Medicationcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Medication_ReportsLocations={
                                      res.Medication_ReportsLocations
                                    }
                                    Medication_ReportsNames={
                                      res.Medication_ReportsNames
                                    }
                                    Medication_Reportlocation={
                                      res.Medication_Reportlocation
                                    }
                                    Medication_Reportname={
                                      res.Medication_Reportname
                                    }
                                    medication_id={res.medication_id}
                                    Medication_name={res.Medication_name}
                                    MedicationDoctor_name={
                                      res.MedicationDoctor_name
                                    }
                                    Medication_startdate={
                                      res.Medication_startdate
                                    }
                                    MedicationNotes={res.MedicationNotes}
                                    MedicationTitle={res.MedicationTitle}
                                    MedicationNext={res.MedicationNext}
                                  ></Medicationcard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {MedicalConDateList ? (
                            <div>
                              {MedicalConDateList.reverse().map((res) => {
                                return (
                                  <Medicalconditioncard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Medicalcondition_ReportsLocations={
                                      res.Medicalcondition_ReportsLocations
                                    }
                                    Medicalcondition_ReportsNames={
                                      res.Medicalcondition_ReportsNames
                                    }
                                    Medicalcondition_Reportlocation={
                                      res.Medicalcondition_Reportlocation
                                    }
                                    Medicalcondition_Reportname={
                                      res.Medicalcondition_Reportname
                                    }
                                    medicalcondition_id={
                                      res.medicalcondition_id
                                    }
                                    Medicalcondition_type={
                                      res.Medicalcondition_type
                                    }
                                    MedicalconditionDoctor_name={
                                      res.MedicalconditionDoctor_name
                                    }
                                    MedicalconditionDiagnosed_date={
                                      res.MedicalconditionDiagnosed_date
                                    }
                                    MedicalconditionNotes={
                                      res.MedicalconditionNotes
                                    }
                                    MedicalconditionTitle={
                                      res.MedicalconditionTitle
                                    }
                                    MedicalconditionNext={
                                      res.MedicalconditionNext
                                    }
                                    MedicalconditionReminderNotes={
                                      res.MedicalconditionReminderNotes
                                    }
                                  ></Medicalconditioncard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                          {MeasureDateList ? (
                            <div>
                              {MeasureDateList.reverse().map((res) => {
                                return (
                                  <Measurementcard
                                    tag_number={
                                      this.props.match.params.tag_number
                                    }
                                    Measurement_ReportsLocations={
                                      res.Measurement_ReportsLocations
                                    }
                                    Measurement_ReportsNames={
                                      res.Measurement_ReportsNames
                                    }
                                    Measurement_Reportlocation={
                                      res.Measurement_Reportlocation
                                    }
                                    Measurement_Reportname={
                                      res.Measurement_Reportname
                                    }
                                    measurement_id={res.measurement_id}
                                    Weight={res.Weight}
                                    Measuredby={res.Measuredby}
                                    Measurement_date={res.Measurement_date}
                                    MeasurementNotes={res.MeasurementNotes}
                                    MeasurementTitle={res.MeasurementTitle}
                                    MeasurementNext={res.MeasurementNext}
                                    MeasurementReminderNotes={
                                      res.MeasurementReminderNotes
                                    }
                                  ></Measurementcard>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="text_container">
                  <p
                    className="no_medical"
                    style={{
                      font: "400 14px/20px Roboto,Helvetica Neue,sans-serif",
                      fontSize: "25px",
                      marginTop: 50,
                    }}
                  >
                    No medical records found
                  </p>
                  <br></br>
                  <p
                    style={{
                      font: "400 14px/20px Roboto,Helvetica Neue,sans-serif",
                      fontSize: "25px",
                    }}
                  >
                    Want to add a medical record?
                  </p>
                  <Button
                    className="mt-3"
                    style={{
                      color: "lightgray",
                      fontSize: "20px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      this.setState({ addnewrecord: true });
                    }}
                  >
                    Click Here
                  </Button>
                </div>
              )}
            </div>
          </div>
          {this.state.showLoader && (
            <div className="loader-container">
              <div className="loader">
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                  }
                  alt="Loading..."
                  loading="lazy"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            </div>
          )}
        </div>

        <Dialog
          PaperProps={{
            style: { borderRadius: 15, maxWidth: "835px" },
          }}
          id="AddRecordDialog"
          open={this.state.addnewrecord}
          onClose={this.handleCloseRecord}
          aria-labelledby="form-dialog-title"
        >
          <AddNewRecord
            className="Add_New_Record_Dialog"
            closeDialog={this.handleCloseRecord}
          />
        </Dialog>
      </>
    );
  }
}
