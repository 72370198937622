import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../petactivation/petactivation.component.css";
import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../api/index.js";
import setCanvasPreview from "../../setcanvasPreview.js";
import {
  Breadcrumbs,
  FormControl,
  FormLabel,
  Link,
  MenuItem,
  TextField,
} from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "@material-ui/core/Modal";
import imageCompression from "browser-image-compression";

import DialogActions from "@mui/material/DialogActions";

import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const baseURL = switchUrls("genie");
const dashboardUrl = switchUrls("gateway");
const RadioButton = withStyles({
  //nishant edits
  root: {
    color: purple[900],
    "&$checked": {
      color: purple[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");
const fromLogin = readCookie("fromLogin");
var today = new Date();
class LocationErrorModal extends React.Component {
  render() {
    const { open, handleClose, handleRetry } = this.props;

    return (
      <Modal open={open} onClose={handleClose}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            background: "white",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            maxWidth: "300px", // Adjust the width as needed
            textAlign: "center",
          }}
        >
          <p style={{ marginBottom: "15px" }}>
            Unable to retrieve your location. Please turn on your GPS.
          </p>
          <button
            onClick={handleRetry}
            style={{
              padding: "10px 15px",
              background: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Retry
          </button>
        </div>
      </Modal>
    );
  }
}

export default class PetActivation extends React.Component {
  constructor(props) {
    super(props);
    this.imageref = React.createRef();
    this.canvasref = React.createRef();
    this.fileInputRef = React.createRef();
    this.state = {
      sidebar: true,
      subMenu: true,
      imageUrl: null,
      selectedImage: null,
      tag_number: this.props.match.params.tag_number,
      tag_status: "active",
      valuable_type: "",
      pet_name: "",
      pet_breed: "",
      pet_color: "",
      pet_gender: "",
      pet_dob: "",
      pet_age: "",
      pet_ident: "",
      pet_microchip: "",
      pet_bloodgrp: "",
      pet_colorother: "",
      pet_breedother: "",
      pet_bloodgrpother: "",
      message: "",
      pet_imagelocation: "",
      locationData: {
        latitude: null,
        longitude: null,
      },
      showLocationErrorModal: false,
      isUploadingImg: false,
      imageUrlvector: "https://storage.googleapis.com/pettag/qr/assets/cat.png",
      valuableImages: {
        Dog: "https://storage.googleapis.com/pettag/qr/assets/dog.png",
        Cat: "https://storage.googleapis.com/pettag/qr/assets/cat.png",
      },
      showLoader: true,
      alreadyActivated: false,
      isBtnDisabled: false,
      imagemodal: false,
      crop: "",
      uploaderror: false,
      imagecroploading: false,
      errorstate: null,
      croperror: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
    this.cropsetup = this.cropsetup.bind(this);
    this.handelimagecrop = this.handelimagecrop.bind(this);
    this.handelcancel = this.handelcancel.bind(this);
  }
  handelcancel() {
    this.setState({
      uploaderror: false,
      message: "",
      imagemodal: false,
      crop: "",
      selectedImage: "",
      imageUrl: "",
    });
  }
  imageSize(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const promise = new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const { height, width } = this;
          resolve({ height, width });
        };
        image.onerror = reject;
      };
    });

    return promise;
  }
  cropsetup(e) {
    console.log("dddddddddddd");
    const { width, height } = e.currentTarget;
    const cropminwidth = (150 / width) * 100;
    const cropAr = makeAspectCrop(
      {
        unit: "%",
        width: cropminwidth,
      },
      1,
      width,
      height
    );
    console.log(cropAr);
    const cropcenter = centerCrop(cropAr, width, height);

    this.setState({ crop: cropcenter });
  }
  handelimagecrop() {
    setCanvasPreview(
      this.imageref?.current,
      this.canvasref?.current,
      convertToPixelCrop(
        this.state.crop,
        this.imageref?.current?.width,
        this.imageref?.current?.height
      )
    );
    console.log(this.state?.selectedImage);
    this.canvasref?.current?.toBlob((blob) => {
      let file = new File([blob], this.state?.selectedImage?.name, {
        type: "image/jpeg",
      });
      this.setState({
        selectedImage: file,
        imageUrl: URL.createObjectURL(blob),
        key_imagelocation: null,
        isuploading: true,
        imagemodal: false,
      });
    });
  }

  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          sessionStorage.setItem("latitude", latitude);
          sessionStorage.setItem("longitude", longitude);
          this.setState({
            locationData: { latitude, longitude },
          });
        },
        (error) => {
          console.error("Error getting location:", error.message);
          this.setState({ showLocationErrorModal: true });
        }
      );
    } else {
      alert("Geolocation is not supported by your browser.");
    }
  };
  componentDidMount() {
    //     let pathname = this.props?.location?.pathname?.split("/");
    // //  pathname=   pathname.split("/")
    //     console.log("tag no: ", pathname[pathname.length -1])
    const state = {
      tag_number: this.state.tag_number,
    };

    axios
      .post(`${baseURL}/api/pet/checkTagNumberWithoutHeader`, state, {
        headers: {
          Authorization: usertoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag not Found by the specified Number") {
          // CheckLfTag(state.tag_number);
          console.log("Tag NOT Found !!!");
        } else if (res.data === "Tag is already activated") {
          console.log("Tag is Already Activated !!!");
          // setShow(false);
          // setAlreadyActivated(true);
          this.setState({ alreadyActivated: true });
        } else {
          console.log("Please Fill the Form and Activate the Tag !!!");
        }
      })
      .catch((error) => {
        console.log("ERROR::checkTagNumberWithoutHeader::",error);
        console.error(error.message);
      });

    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });
    setTimeout(() => {
      this.setState({ showLoader: false });
    }, 1000);
    this.getLocation();
  }

  //  handleActivated = () => this.setState({alreadyActivated:false});

  handleDropdownChange(event) {
    console.log("valueType: ", event);
    this.setState({
      valuable_type: event.target.value,
      imageUrlvector: this.state.valuableImages[event.target.value],
      pet_imagelocation: null,

      isUploadingImg: false,
    });
  }
  handleRetry = () => {
    this.setState({ showLocationErrorModal: false });
    this.getLocation();
  };
  async handleChange(event) {
    this.setState({ imagecroploading: true });
    if (event.target.files[0]) {
      var sizeinmb = event.target.files[0].size / (1024 * 1024);

      if (sizeinmb > 5) {
        this.setState({
          message: "File size should be less than 5 MB",
          uploaderror: true,
          imagemodal: true,
        });
      } else {
        this.setState({ showLoader: true });
        const imageDimension = await this.imageSize(event.target.files[0]);
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight:
            imageDimension?.width > 1300 ? 1300 : imageDimension?.width,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(
          event.target.files[0],
          options
        );
        const selectedImage = compressedFile;
        this.setState({ showLoader: false });
        if (selectedImage?.type) {
          if (
            selectedImage?.type === "image/jpeg" ||
            selectedImage?.type === "image/png" ||
            selectedImage?.type === "image/gif"
          ) {
            this.setState({
              imagemodal: true,
              selectedImage: compressedFile,
              imageUrl: URL.createObjectURL(compressedFile),
              isuploading: true,
              imagecroploading: false,
            });
          } else {
            this.setState({
              message: "Only Png and Jpeg Images Are Acceptable",
              uploaderror: true,
              imagemodal: true,
              imagecroploading: false,
            });
          }
        } else {
          this.setState({
            message: "Only Png and Jpeg Images Are Acceptable",
            uploaderror: true,
            imagemodal: true,
            imagecroploading: false,
          });
        }
        if (this.fileInputRef.current) {
          this.fileInputRef.current.value = "";
        }
      }
    } else {
      console.log("failed");
    }
  }
  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeHandlerdate = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    const birthDate = new Date(e.target.value);
    const difference = Date.now() - birthDate.getTime();
    const age = new Date(difference);
    const ageyear = Math.abs(age.getUTCFullYear() - 1970);
    this.setState({ pet_age: ageyear });
  };

  deleteimage = (e) => {
    // axios
    //   .post(`${baseURL}/api/pet/deleteimage`, this.state)
    //   .then((response) => {
    //     if (response.data === "Pet Image removed") {
    //       this.setState({
    //         imageUrl: PetImage,
    //         pet_imagelocation: null,
    //         pet_imagename: null,
    //       });
    //     } else if (response.data === "wrong data") {
    //       this.setState({
    //         snackbaropen: true,
    //         snackbarmsg: "Image could not be deleted",
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
    //   });

    this.setState({
      imageUrl: null,
      pet_imagelocation: null,
      pet_imagename: null,
      imageUrlvector: this.state.imageUrlvector,
      isUploadingImg: false,
    });
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };
  submitHandler = (e) => {
    e.preventDefault();
    console.log(this.state);

    this.setState({ isBtnDisabled: true });
    const {
      tag_status,
      valuable_type,
      pet_name,
      pet_breed,
      pet_color,
      pet_gender,
      pet_dob,
      pet_age,
      pet_ident,
      pet_microchip,
      pet_bloodgrp,
      pet_colorother,
      pet_breedother,
      pet_bloodgrpother,
      pet_imagelocation,
    } = this.state;
    const { latitude, longitude } = this.state.locationData;
    const isActivatedFromCRM = fromLogin === "CRM" ? true : false;

    const data = {
      tag_number: this.props.match.params.tag_number,
      tag_status,
      valuable_type,
      pet_name,
      pet_breed,
      pet_color,
      pet_gender,
      pet_dob,
      pet_age,
      pet_ident,
      pet_microchip,
      pet_bloodgrp,
      pet_colorother,
      pet_breedother,
      pet_bloodgrpother,
      pet_imagelocation,
      latitude,
      longitude,
      isActivatedFromCRM
    };

    axios
      .post(`${baseURL}/api/pet/petActivation/`, data, {
        headers: { Authorization: usertoken },
      })
      .then((response) => {
        console.log(response);
        if (response.data === "Tag Already Activated") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
          this.setState({ alreadyActivated: true });
        } else if (response.data === "Tag not Found by the specified Number") {
          this.setState({ snackbaropen: true, snackbarmsg: response.data });
        } else if (response.data === "Pet Details Registered") {
          if (this?.state?.selectedImage) {
            const fd = new FormData();
            fd.append("image", this.state.selectedImage);
            fd.append("tag_number", this.props.match.params.tag_number);
            axios
              .post(`${baseURL}/api/pet/petimage-upload`, fd)
              .then((res) => {
                console.log(res);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Pet Details Registered",
                });
                window.location.href =
                  "/#/pet-parent-details/" + this.props.match.params.tag_number;
              })
              .catch((error) => {
                console.log(error);
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Pet Activation failed",
                });
                this.setState({ isBtnDisabled: false });
              });
          } else {
            this.setState({
              snackbaropen: true,
              snackbarmsg: "Pet Details Registered",
            });

            window.location.href =
              "/#/pet-parent-details/" + this.props.match.params.tag_number;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          snackbaropen: true,
          snackbarmsg: "Pet Activation failed",
        });
      });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  // Breadcrumb handle click
  swithBreadcrumbs = (e, tab) => {
    // if (tab === "PET_DETAILS") {
    //   this.setState({ showPetDetails: true });
    //   this.setState({ showPet_ParentDetails: false });
    // }
    if (tab === "PET_PARENT_DETAILS") {
      // window.location.href =
      //   "/#/pet-parent-details/" + this.props.match.params.tag_number;
    }
  };

  render() {
    const {
      sidebar,
      subMenu,
      imageUrl,
      tag_number,
      valuable_type,
      pet_name,
      pet_breed,
      pet_color,
      pet_gender,
      pet_dob,
      pet_age,
      pet_ident,
      pet_microchip,
      pet_bloodgrp,
      pet_colorother,
      pet_breedother,
      pet_bloodgrpother,
      pet_imagelocation,
      isUploadingImg,
      imageUrlvector,
      locationData,
      showLocationErrorModal,
      imagemodal,
      crop,
      uploaderror,
      message,
    } = this.state;
    const { latitude, longitude } = locationData;
    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />

        <div className="editPetDetails-main-wrapper">
          <div className="editPetDetails-left-wrapper">
            <div className="petActivation-sidebar-toggle-btn-wrapper">
              <button
                className="petActivation-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {this.state.sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "petActivation-sidebar-container-hide"
                  : "petActivation-sidebar-container"
              }
            >
              <button
                className="petActivation-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS{" "}
                {subMenu ? (
                  <AiIcons.AiFillCaretUp />
                ) : (
                  <AiIcons.AiFillCaretDown />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "petActivation-sidebar-menu-ul-hide"
                    : "petActivation-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="petActivation-sidebar-menu-ul-links"
                    href={`/dashboard/` + this.props.match.params.tag_number}
                  >
                    ALL TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="petActivation-sidebar-menu-ul-links"
                    href={`/dashboard/` + this.props.match.params.tag_number}
                  >
                    ACTIVE | INACTIVE TAGS
                  </button>
                </li>

                <li>
                  <button
                    className="petActivation-sidebar-menu-ul-links"
                    href={`/dashboard/` + this.props.match.params.tag_number}
                  >
                    LOST | FOUND TAGS
                  </button>
                </li>
              </ul>

              <button
                className="petActivation-sidebar-menu-link"
                href={`/dashboard/` + this.props.match.params.tag_number}
              >
                ACTIVATE TAG
              </button>

              <button
                className="petActivation-sidebar-menu-link"
                href={`/#/calendar/` + this.props.match.params.tag_number}
              >
                CALENDAR
              </button>

              <button
                className="petActivation-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/scan-notification/" + localStorage.getItem("uid");
                }}
              >
                NOTIFICATIONS
              </button>

              <button
                className="petActivation-sidebar-menu-link"
                href={
                  `/#/pet-parent-details/` + this.props.match.params.tag_number
                }
              >
                PET PARENT DETAILS
              </button>

              <button
                className="petActivation-sidebar-menu-link"
                href={`/#/pet-details/` + this.props.match.params.tag_number}
              >
                PET DETAILS
              </button>

              <button
                className="petActivation-sidebar-menu-link"
                href={
                  `https://dashboard.tag8.in/#/medical-dashboard/` +
                  this.props.match.params.tag_number
                }
              >
                PET MEDICAL DETAILS
              </button>
            </div>
          </div>

          <div className="editPetDetails-right-wrapper">
            <div id="breadcrumbs" className="mb-3">
              <Breadcrumbs
                className="mui-breadcrumb"
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
              >
                <Link
                  className="breadcrumb-link"
                  color="textPrimary"
                  onClick={(e) => this.swithBreadcrumbs(e, "PET_DETAILS")}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "12px",
                    color: "#fcbc1b",
                  }}
                >
                  Pet Activation
                </Link>
                <Link
                  className="breadcrumb-link"
                  color="inherit"
                  onClick={(e) =>
                    this.swithBreadcrumbs(e, "PET_PARENT_DETAILS")
                  }
                  style={{
                    cursor: "pointer",
                    fontSize: "12px",
                    color: "#fcbc1b",
                  }}
                >
                  Pet Parent Details
                </Link>
              </Breadcrumbs>
            </div>
            <div className="petDetails ">
              <div className="editPetDetails-form-container ">
                <div className="editPetDetails-form-container-top">
                  <div className="editPetDetails-user-img">
                    {imageUrl ? (
                      <img
                        alt="pet-img-uploaded"
                        src={imageUrl}
                        className="editPetDetails-image rounded-circle"
                      />
                    ) : pet_imagelocation ? (
                      <img
                        alt="pet-img-uploadeding"
                        src={pet_imagelocation}
                        className="editPetDetails-image rounded-circle"
                      />
                    ) : (
                      <img
                        alt="pet-img-default"
                        src={imageUrlvector}
                        className="editPetDetails-image"
                        style={{ padding: "1.5rem" }}
                      />
                    )}
                  </div>
                  <div style={{display:"flex"}} className="editPetDetails-edit-btn-wrap">
                   <div style={{marginRight:"1rem"}}>
                   <label
                      htmlFor="upload"
                      className="editPetDetails-edit-button-image"
                      style={{ cursor: "pointer" }}
                    >
                      <MdIcons.MdEdit /> Change
                    </label>

                    <input
                      ref={this.fileInputRef}
                      id="upload"
                      className="editPetDetails-input-user-img"
                      type="file"
                      onChange={this.handleChange}
                    />

                   </div>
                   <div>
                   {pet_imagelocation || imageUrl ? (
                      <label
                        className="editPetDetails-delete-button-image "
                        onClick={this.deleteimage}
                      >
                        <AiIcons.AiFillDelete /> Remove
                      </label>
                    ) : null}
                   </div>
                  </div>
                  <div
                    className=" editPetDetails-user-qr mt-3 d-flex"
                    style={{ gap: "6px" }}
                  >
                    <img
                      alt="qr"
                      src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                      style={{ width: "30px", height: "30px" }}
                    />
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "1.2rem",
                        margin: "auto",
                      }}
                    >
                      <b>
                        {tag_number && tag_number !== "-" ? tag_number : "N/A"}
                      </b>
                    </p>
                  </div>
                </div>

                <div className="editPetDetails-form-container-bottom mt-3">
                  <p style={{ marginLeft: "4rem" }}>
                    <strong>Pet Activation</strong>
                  </p>
                  <form
                    className="editPetDetails-form"
                    onSubmit={this.submitHandler}
                  >
                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          variant="outlined"
                          select
                          label="Select Pet Type"
                          value={valuable_type}
                          onChange={this.handleDropdownChange}
                          required
                        >
                          <MenuItem value="Cat">Cat</MenuItem>
                          <MenuItem value="Dog">Dog</MenuItem>
                        </TextField>
                      </div>

                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          label="Pet Name"
                          type="text"
                          variant="outlined"
                          // maxLength="20"
                          inputProps={{ maxLength: 12 }}
                          name="pet_name"
                          value={pet_name}
                          onChange={this.changeHandler}
                          required
                        />
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        {!valuable_type ? (
                          <TextField
                            variant="outlined"
                            select
                            label="Pet Breed"
                            value={pet_breed}
                            onChange={this.changeHandler}
                            required
                          >
                            <MenuItem defaultValue disabled hidden></MenuItem>
                          </TextField>
                        ) : (
                          <div></div>
                        )}

                        {valuable_type === "Dog" ? (
                          <>
                            <TextField
                              select
                              label="Pet Breed"
                              name="pet_breed"
                              value={pet_breed}
                              onChange={this.changeHandler}
                              variant="outlined"
                            >
                              <MenuItem hidden></MenuItem>
                              <MenuItem value="Affenpinscher">
                                Affenpinscher
                              </MenuItem>
                              <MenuItem value="Afghan Hound">
                                Afghan Hound
                              </MenuItem>
                              <MenuItem value="Airedale Terrier">
                                Airedale Terrier
                              </MenuItem>
                              <MenuItem value="Akita">Akita</MenuItem>
                              <MenuItem value="Alaskan Malamute">
                                Alaskan Malamute
                              </MenuItem>
                              <MenuItem value="AmericanPitbull">
                                American Pit bull
                              </MenuItem>
                              <MenuItem value="American Staffordshire Terrier">
                                American Staffordshire Terrier
                              </MenuItem>
                              <MenuItem value="American Water Spaniel">
                                American Water Spaniel
                              </MenuItem>
                              <MenuItem value="Australian Cattle Dog">
                                Australian Cattle Dog
                              </MenuItem>
                              <MenuItem value="Australian Shepherd">
                                Australian Shepherd
                              </MenuItem>
                              <MenuItem value="Australian Terrier">
                                Australian Terrier
                              </MenuItem>
                              <MenuItem value="Basenji">Basenji</MenuItem>
                              <MenuItem value="Basset Hound">
                                Basset Hound
                              </MenuItem>
                              <MenuItem value="Beagle">Beagle</MenuItem>
                              <MenuItem value="Bearded Collie">
                                Bearded Collie
                              </MenuItem>
                              <MenuItem value="Bedlington Terrier">
                                Bedlington Terrier
                              </MenuItem>
                              <MenuItem value="Bernese Mountain Dog">
                                Bernese Mountain Dog
                              </MenuItem>
                              <MenuItem value="Bichon Frise">
                                Bichon Frise
                              </MenuItem>
                              <MenuItem value="Black And Tan Coonhound">
                                Black And Tan Coonhound
                              </MenuItem>
                              <MenuItem value="Bloodhound">Bloodhound</MenuItem>
                              <MenuItem value="Border Collie">
                                Border Collie
                              </MenuItem>
                              <MenuItem value="Border Terrier">
                                Border Terrier
                              </MenuItem>
                              <MenuItem value="Borzoi">Borzoi</MenuItem>
                              <MenuItem value="Boston Terrier">
                                Boston Terrier
                              </MenuItem>
                              <MenuItem value="Bouvier Des Flandres">
                                Bouvier Des Flandres
                              </MenuItem>
                              <MenuItem value="Boxer">Boxer</MenuItem>
                              <MenuItem value="Briard">Briard</MenuItem>
                              <MenuItem value="Brittany">Brittany</MenuItem>
                              <MenuItem value="Brussels Griffon">
                                Brussels Griffon
                              </MenuItem>
                              <MenuItem value="Bull Terrier">
                                Bull Terrier
                              </MenuItem>
                              <MenuItem value="Bulldog">Bulldog</MenuItem>
                              <MenuItem value="Bullmastiff">
                                Bullmastiff
                              </MenuItem>
                              <MenuItem value="Cairn Terrier">
                                Cairn Terrier
                              </MenuItem>
                              <MenuItem value="Canaan Dog">Canaan Dog</MenuItem>
                              <MenuItem value="Chesapeake Bay Retriever">
                                Chesapeake Bay Retriever
                              </MenuItem>
                              <MenuItem value="Chihuahua">Chihuahua</MenuItem>
                              <MenuItem value="Chinese Crested">
                                Chinese Crested
                              </MenuItem>
                              <MenuItem value="Chinese Shar-Pei">
                                Chinese Shar-Pei
                              </MenuItem>
                              <MenuItem value="Chow Chow">Chow Chow</MenuItem>
                              <MenuItem value="Clumber Spaniel">
                                Clumber Spaniel
                              </MenuItem>
                              <MenuItem value="Cocker Spaniel">
                                Cocker Spaniel
                              </MenuItem>
                              <MenuItem value="Collie">Collie</MenuItem>
                              <MenuItem value="Cross Breed">
                                Cross Breed
                              </MenuItem>
                              <MenuItem value="Curly-Coated Retriever">
                                Curly-Coated Retriever
                              </MenuItem>
                              <MenuItem value="Dachshund">Dachshund</MenuItem>
                              <MenuItem value="Dalmatian">Dalmatian</MenuItem>
                              <MenuItem value="Doberman Pinscher">
                                Doberman Pinscher
                              </MenuItem>
                              <MenuItem value="English Cocker Spaniel">
                                English Cocker Spaniel
                              </MenuItem>
                              <MenuItem value="English Setter">
                                English Setter
                              </MenuItem>
                              <MenuItem value="English Springer Spaniel">
                                English Springer Spaniel
                              </MenuItem>
                              <MenuItem value="English Toy Spaniel">
                                English Toy Spaniel
                              </MenuItem>
                              <MenuItem value="Eskimo Dog">Eskimo Dog</MenuItem>
                              <MenuItem value="Finnish Spitz">
                                Finnish Spitz
                              </MenuItem>
                              <MenuItem value="Flat-Coated Retriever">
                                Flat-Coated Retriever
                              </MenuItem>
                              <MenuItem value="Fox Terrier">
                                Fox Terrier
                              </MenuItem>
                              <MenuItem value="Foxhound">Foxhound</MenuItem>
                              <MenuItem value="French Bulldog">
                                French Bulldog
                              </MenuItem>
                              <MenuItem value="German Shepherd">
                                German Shepherd
                              </MenuItem>
                              <MenuItem value="German Shorthaired Pointer">
                                German Shorthaired Pointer
                              </MenuItem>
                              <MenuItem value="German Wirehaired Pointer">
                                German Wirehaired Pointer
                              </MenuItem>
                              <MenuItem value="Golden Retriever">
                                Golden Retriever
                              </MenuItem>
                              <MenuItem value="Gordon Setter">
                                Gordon Setter
                              </MenuItem>
                              <MenuItem value="Great Dane">Great Dane</MenuItem>
                              <MenuItem value="Greyhound">Greyhound</MenuItem>
                              <MenuItem value="Indian Paraigh Dog">
                                Indian Paraigh Dog
                              </MenuItem>
                              <MenuItem value="Irish Setter">
                                Irish Setter
                              </MenuItem>
                              <MenuItem value="Irish Water Spaniel">
                                Irish Water Spaniel
                              </MenuItem>
                              <MenuItem value="Irish Wolfhound">
                                Irish Wolfhound
                              </MenuItem>
                              <MenuItem value="Jack Russell Terrier">
                                Jack Russell Terrier
                              </MenuItem>
                              <MenuItem value="Japanese Spaniel">
                                Japanese Spaniel
                              </MenuItem>
                              <MenuItem value="Keeshond">Keeshond</MenuItem>
                              <MenuItem value="Kerry Blue Terrier">
                                Kerry Blue Terrier
                              </MenuItem>
                              <MenuItem value="Komondor">Komondor</MenuItem>
                              <MenuItem value="Kuvasz">Kuvasz</MenuItem>
                              <MenuItem value="Labrador">Labrador</MenuItem>
                              <MenuItem value="Labrador Retriever">
                                Labrador Retriever
                              </MenuItem>
                              <MenuItem value="Lakeland Terrier">
                                Lakeland Terrier
                              </MenuItem>
                              <MenuItem value="Lhasa Apso">Lhasa Apso</MenuItem>
                              <MenuItem value="Maltese">Maltese</MenuItem>
                              <MenuItem value="Manchester Terrier">
                                Manchester Terrier
                              </MenuItem>
                              <MenuItem value="Mastiff">Mastiff</MenuItem>
                              <MenuItem value="Mexican Hairless">
                                Mexican Hairless
                              </MenuItem>
                              <MenuItem value="Mixed Breed">
                                Mixed Breed
                              </MenuItem>
                              <MenuItem value="Mongreal">Mongreal</MenuItem>
                              <MenuItem value="Mudhol">Mudhol</MenuItem>
                              <MenuItem value="Newfoundland">
                                Newfoundland
                              </MenuItem>
                              <MenuItem value="Norwegian Elkhound">
                                Norwegian Elkhound
                              </MenuItem>
                              <MenuItem value="Norwich Terrier">
                                Norwich Terrier
                              </MenuItem>
                              <MenuItem value="Otterhound">Otterhound</MenuItem>
                              <MenuItem value="Papillon">Papillon</MenuItem>
                              <MenuItem value="Pekingese">Pekingese</MenuItem>
                              <MenuItem value="Pointer">Pointer</MenuItem>
                              <MenuItem value="Poodle">Poodle</MenuItem>
                              <MenuItem value="Pomeranian">Pomeranian</MenuItem>
                              <MenuItem value="Pug">Pug</MenuItem>
                              <MenuItem value="Puli">Puli</MenuItem>
                              <MenuItem value="Rhodesian Ridgeback">
                                Rhodesian Ridgeback
                              </MenuItem>
                              <MenuItem value="Rottweiler">Rottweiler</MenuItem>
                              <MenuItem value="Saint Bernard">
                                Saint Bernard
                              </MenuItem>
                              <MenuItem value="Saluki">Saluki</MenuItem>
                              <MenuItem value="Samoyed">Samoyed</MenuItem>
                              <MenuItem value="Schipperke">Schipperke</MenuItem>
                              <MenuItem value="Schnauzer">Schnauzer</MenuItem>
                              <MenuItem value="Scottish Deerhound">
                                Scottish Deerhound
                              </MenuItem>
                              <MenuItem value="Scottish Terrier">
                                Scottish Terrier
                              </MenuItem>
                              <MenuItem value="Sealyham Terrier">
                                Sealyham Terrier
                              </MenuItem>
                              <MenuItem value="Shetland Sheepdog">
                                Shetland Sheepdog
                              </MenuItem>
                              <MenuItem value="Shihtzu">Shihtzu</MenuItem>
                              <MenuItem value="Siberian Husky">
                                Siberian Husky
                              </MenuItem>
                              <MenuItem value="Silky Terrier">
                                Silky Terrier
                              </MenuItem>
                              <MenuItem value="Skye Terrier">
                                Skye Terrier
                              </MenuItem>
                              <MenuItem value="Staffordshire Bull Terrier">
                                Staffordshire Bull Terrier
                              </MenuItem>
                              <MenuItem value="Soft-Coated Wheaten Terrier">
                                Soft-Coated Wheaten Terrier
                              </MenuItem>
                              <MenuItem value="Sussex Spaniel">
                                Sussex Spaniel
                              </MenuItem>
                              <MenuItem value="Spitz">Spitz</MenuItem>
                              <MenuItem value="Tibetan Terrier">
                                Tibetan Terrier
                              </MenuItem>
                              <MenuItem value="Vizsla">Vizsla</MenuItem>
                              <MenuItem value="Weimaraner">Weimaraner</MenuItem>
                              <MenuItem value="Welsh Terrier">
                                Welsh Terrier
                              </MenuItem>
                              <MenuItem value="West Highland White Terrier">
                                West Highland White Terrier
                              </MenuItem>
                              <MenuItem value="Whippet">Whippet</MenuItem>
                              <MenuItem value="Yorkshire Terrier">
                                Yorkshire Terrier
                              </MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </>
                        ) : (
                          <div></div>
                        )}

                        {valuable_type === "Cat" ? (
                          <>
                            <TextField
                              // className="editPetDetails-select-tag"
                              select
                              label="Pet Breed"
                              name="pet_breed"
                              value={pet_breed}
                              onChange={this.changeHandler}
                              variant="outlined"
                            >
                              <MenuItem hidden></MenuItem>
                              <MenuItem value="Abyssinian">Abyssinian</MenuItem>
                              <MenuItem value="American Bobtail">
                                American Bobtail
                              </MenuItem>
                              <MenuItem value="American Curl">
                                American Curl
                              </MenuItem>
                              <MenuItem value="American Shorthair">
                                American Shorthair
                              </MenuItem>
                              <MenuItem value="American Wirehair">
                                American Wirehair
                              </MenuItem>
                              <MenuItem value="Balinese">Balinese</MenuItem>
                              <MenuItem value="Bengal">Bengal</MenuItem>
                              <MenuItem value="Birman">Birman</MenuItem>
                              <MenuItem value="Bombay">Bombay</MenuItem>
                              <MenuItem value="British Shorthair">
                                British Shorthair
                              </MenuItem>
                              <MenuItem value="Burmese">Burmese</MenuItem>
                              <MenuItem value="Chartreux">Chartreux</MenuItem>
                              <MenuItem value="Chausie">Chausie</MenuItem>
                              <MenuItem value="Cornish Rex">
                                Cornish Rex
                              </MenuItem>
                              <MenuItem value="Devon Rex">Devon Rex</MenuItem>
                              <MenuItem value="Donskoy">Donskoy</MenuItem>
                              <MenuItem value="Egyptian Mau">
                                Egyptian Mau
                              </MenuItem>
                              <MenuItem value="Exotic Shorthair">
                                Exotic Shorthair
                              </MenuItem>
                              <MenuItem value="Havana">Havana</MenuItem>
                              <MenuItem value="Highlander">Highlander</MenuItem>
                              <MenuItem value="Himalayan">Himalayan</MenuItem>
                              <MenuItem value="Household Pet Cat">
                                Household Pet Cat
                              </MenuItem>
                              <MenuItem value="Household Pet Kitten">
                                Household Pet Kitten
                              </MenuItem>
                              <MenuItem value="Japanese Bobtail">
                                Japanese Bobtail
                              </MenuItem>
                              <MenuItem value="Korat">Korat</MenuItem>
                              <MenuItem value="Kurilian Bobtail">
                                Kurilian Bobtail
                              </MenuItem>
                              <MenuItem value="Laperm">Laperm</MenuItem>
                              <MenuItem value="Maine Coon">Maine Coon</MenuItem>
                              <MenuItem value="Manx">Manx</MenuItem>
                              <MenuItem value="Minskin">Minskin</MenuItem>
                              <MenuItem value="Munchkin">Munchkin</MenuItem>
                              <MenuItem value="Nebelung">Nebelung</MenuItem>
                              <MenuItem value="Norwegian Forest Cat">
                                Norwegian Forest Cat
                              </MenuItem>
                              <MenuItem value="Ocicat">Ocicat</MenuItem>
                              <MenuItem value="Ojos Azules">
                                Ojos Azules
                              </MenuItem>
                              <MenuItem value="Oriental">Oriental</MenuItem>
                              <MenuItem value="Persian">Persian</MenuItem>
                              <MenuItem value="Peterbald">Peterbald</MenuItem>
                              <MenuItem value="Pixiebob">Pixiebob</MenuItem>
                              <MenuItem value="Ragdoll">Ragdoll</MenuItem>
                              <MenuItem value="Russian Blue">
                                Russian Blue
                              </MenuItem>
                              <MenuItem value="Savannah">Savannah</MenuItem>
                              <MenuItem value="Scottish Fold">
                                Scottish Fold
                              </MenuItem>
                              <MenuItem value="Selkirk Rex">
                                Selkirk Rex
                              </MenuItem>
                              <MenuItem value="Serengeti">Serengeti</MenuItem>
                              <MenuItem value="Siberian">Siberian</MenuItem>
                              <MenuItem value="Siamese">Siamese</MenuItem>
                              <MenuItem value="Singapura">Singapura</MenuItem>
                              <MenuItem value="Snowshoe">Snowshoe</MenuItem>
                              <MenuItem value="Sokoke">Sokoke</MenuItem>
                              <MenuItem value="Somali">Somali</MenuItem>
                              <MenuItem value="Sphynx">Sphynx</MenuItem>
                              <MenuItem value="Thai">Thai</MenuItem>
                              <MenuItem value="Tonkinese">Tonkinese</MenuItem>
                              <MenuItem value="Toyger">Toyger</MenuItem>
                              <MenuItem value="Turkish Angora">
                                Turkish Angora
                              </MenuItem>
                              <MenuItem value="Turkish Van">
                                Turkish Van
                              </MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </>
                        ) : null}

                        {pet_breed === "Other" ? (
                          <TextField
                            type="text"
                            className="mt-2"
                            // maxlength="20"
                            inputProps={{ maxLength: 12 }}
                            value={pet_breedother}
                            onChange={this.changeHandler}
                            name="pet_breedother"
                            label="Other Pet Breed"
                            variant="outlined"
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>

                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          select
                          label="Pet Color"
                          name="pet_color"
                          value={pet_color}
                          onChange={this.changeHandler}
                          variant="outlined"
                          required
                        >
                          <MenuItem value="white">White</MenuItem>
                          <MenuItem value="brown">Brown</MenuItem>
                          <MenuItem value="black">Black</MenuItem>
                          <MenuItem value="beige">Beige</MenuItem>
                          <MenuItem value="golden">Golden</MenuItem>
                          <MenuItem value="grey">Grey</MenuItem>
                          <MenuItem value="chocolate">Chocolate</MenuItem>
                          <MenuItem value="silver">Silver</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </TextField>
                        {pet_color === "Other" ? (
                          <TextField
                            type="text"
                            variant="outlined"
                            label="Pet Other Color"
                            className="mt-2"
                            // maxlength="20"
                            inputProps={{ maxLength: 12 }}
                            value={pet_colorother}
                            onChange={this.changeHandler}
                            name="pet_colorother"
                            required
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>

                    <div
                      className="two-field-wrapper"
                      // style={{ marginBottom: "-10px" }}
                    >
                      <div className="editPetDetails-input-wrapper">
                        {!valuable_type ? (
                          <TextField
                            select
                            label="Blood Group"
                            value={pet_breed}
                            onChange={this.changeHandler}
                            variant="outlined"
                            required
                          >
                            <MenuItem disabled hidden></MenuItem>
                          </TextField>
                        ) : (
                          <div></div>
                        )}

                        {valuable_type === "Dog" ? (
                          <>
                            <TextField
                              select
                              label="Blood Group"
                              name="pet_bloodgrp"
                              value={pet_bloodgrp}
                              onChange={this.changeHandler}
                              variant="outlined"
                              required
                            >
                              <MenuItem hidden></MenuItem>
                              <MenuItem value="dea1.1+">DEA-1.1 +</MenuItem>
                              <MenuItem value="dea1.1-">DEA-1.1 -</MenuItem>
                              <MenuItem value="dea1.2+">DEA-1.2 +</MenuItem>
                              <MenuItem value="dea1.2-">DEA-1.2 -</MenuItem>
                              <MenuItem value="dea1.3+">DEA-3 +</MenuItem>
                              <MenuItem value="dea1.3-">DEA-3 -</MenuItem>
                              <MenuItem value="dea1.4+">DEA-4 +</MenuItem>
                              <MenuItem value="dea1.4-">DEA-4 -</MenuItem>
                              <MenuItem value="dea1.5+">DEA-5 +</MenuItem>
                              <MenuItem value="dea1.5-">DEA-5 -</MenuItem>
                              <MenuItem value="dea1.7+">DEA-7 +</MenuItem>
                              <MenuItem value="dea1.7-">DEA-7 -</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </>
                        ) : (
                          <div></div>
                        )}

                        {valuable_type === "Cat" ? (
                          <>
                            <TextField
                              select
                              label="Blood Group"
                              name="pet_bloodgrp"
                              value={pet_bloodgrp}
                              onChange={this.changeHandler}
                              variant="outlined"
                              required
                            >
                              <MenuItem hidden></MenuItem>
                              <MenuItem value="a+">A+</MenuItem>
                              <MenuItem value="a-">A-</MenuItem>
                              <MenuItem value="b+">B+</MenuItem>
                              <MenuItem value="b-">B-</MenuItem>
                              <MenuItem value="ab+">AB+</MenuItem>
                              <MenuItem value="ab-">AB-</MenuItem>
                              <MenuItem value="Other">Other</MenuItem>
                            </TextField>
                          </>
                        ) : (
                          <div></div>
                        )}

                        {pet_bloodgrp === "Other" ? (
                          <TextField
                            type="text"
                            className="mt-2 mb-3"
                            // maxlength="20"
                            inputProps={{ maxLength: 12 }}
                            value={pet_bloodgrpother}
                            onChange={this.changeHandler}
                            name="pet_bloodgrpother"
                            variant="outlined"
                            label="Other Pet Blood Group"
                            required
                          />
                        ) : (
                          <div></div>
                        )}
                      </div>

                      <div className="editPetDetails-input-wrapper">
                        <FormControl>
                          <FormLabel className="gender-lebal">
                            Gender *
                          </FormLabel>
                          <RadioGroup
                            aria-label="pet_gender"
                            name="pet_gender"
                            value={pet_gender}
                            onChange={this.changeHandler}
                            required
                            row
                          >
                            <FormControlLabel
                              value="male"
                              control={<RadioButton />}
                              label="Male"
                              required
                            />

                            <FormControlLabel
                              value="female"
                              control={<RadioButton />}
                              label="Female"
                              required
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          label="Date of Birth"
                          variant="outlined"
                          type="date"
                          name="pet_dob"
                          required
                          value={pet_dob}
                          onChange={this.changeHandlerdate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            max: today,
                          }}
                        />
                      </div>

                      <div
                        className="editPetDetails-input-wrapper"
                        id="select-pet-age"
                      >
                        <TextField
                          label="Age"
                          type="number"
                          variant="outlined"
                          // className="editPetDetails-input-tag"
                          value={pet_age}
                          onChange={this.changeHandler}
                          name="pet_age"
                        />
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editPetDetails-input-wrapper">
                        <TextField
                          label="Identification Mark"
                          variant="outlined"
                          type="text"
                          // className="editPetDetails-input-tag"
                          // maxLength="20"
                          inputProps={{ maxLength: 35 }}
                          value={pet_ident}
                          onChange={this.changeHandler}
                          name="pet_ident"
                          required
                        />
                      </div>

                      <div
                        className="editPetDetails-input-wrapper"
                        id="select-pet-micno"
                      >
                        <TextField
                          label="Microchip Number"
                          variant="outlined"
                          type="number"
                          // className="editPetDetails-input-tag"
                          value={pet_microchip}
                          onChange={this.changeHandler}
                          name="pet_microchip"
                        />
                      </div>
                    </div>
                    <LocationErrorModal
                      open={showLocationErrorModal}
                      handleClose={() =>
                        this.setState({ showLocationErrorModal: false })
                      }
                      handleRetry={this.handleRetry}
                      latitude={latitude}
                      longitude={longitude}
                    />
                    <div className="editPetDetails-btn-row">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ width: "100%" }}
                        className={`submit-login ${
                          this.state.isBtnDisabled
                            ? "bg-secondary"
                            : "bg-warning"
                        } text-white mt-2`}
                        disabled={this.state.isBtnDisabled}
                      >
                        {this.state.isBtnDisabled ? "Wait" : "SUBMIT"}
                      </Button>

                      <Button
                        type="button"
                        variant="contained"
                        style={{ width: "100%" }}
                        className="bg-white mt-2 cancel-btn submit-login "
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                      >
                        CANCEL
                      </Button>
                    </div>
                    {this.state.showLoader && (
                      <div className="loader-container">
                        <div className="loader">
                          <img
                            src={
                              "https://storage.googleapis.com/pettag/qr/assets/loader.gif"
                            }
                            alt="Loading..."
                            loading="lazy"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.alreadyActivated}
          // onClose={handleActivated}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent className="small__popup_">
            <h3>Tag is already activated</h3>
            <Button
              variant="contained"
              type="button"
              style={{ width: "100%" }}
              className="submit-login bg-warning text-white mt-2 goto-mytag-btn"
              onClick={() => {
                window.location.href =
                  `${dashboardUrl}/dashboard/` + localStorage.getItem("uid");
              }}
            >
              Go To My Tags
            </Button>
            {/* <Button
                        type="button"
                        variant="contained"
                        style={{ width: "100%" }}
                        className="bg-white mt-2 cancel-btn submit-login "
                        onClick={() => {
                          window.location.href =
                            "/#/pet-parent-details/" + this.props.match.params.tag_number;
                        }}
                      >
                       Go To Parent Details
                      </Button> */}
          </DialogContent>
        </Dialog>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={8000}
          onClose={this.snackbarClose}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              X
            </IconButton>,
          ]}
        />
        <Dialog open={imagemodal} fullWidth="sm" maxWidth="sm">
          <DialogTitle>Image</DialogTitle>
          <DialogContent>
            <form>
              <div style={{ margin: "1rem" }}>
                {!uploaderror ? (
                  <>
                    <ReactCrop
                      keepSelection
                      crop={crop}
                      aspect={1}
                      minWidth={100}
                      onChange={(percentCrop) => {
                        this.setState({ crop: percentCrop });
                      }}
                    >
                      <img
                        ref={this.imageref}
                        alt="crop"
                        width="100%"
                        height="100%"
                        src={imageUrl}
                        onLoad={this.cropsetup}
                      ></img>
                    </ReactCrop>
                  </>
                ) : (
                  <>
                    <p style={{ color: "red", textAlign: "center" }}>
                      {message}
                    </p>
                  </>
                )}
              </div>

              <div className="crop-actionsSub">
                <Button onClick={this.handelcancel}>Cancel</Button>
                {!uploaderror ? (
                  <Button onClick={this.handelimagecrop}>Submit</Button>
                ) : null}
              </div>
            </form>
          </DialogContent>
          <canvas ref={this.canvasref} style={{ display: "none" }}></canvas>
        </Dialog>
      </>
    );
  }
}
